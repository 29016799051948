import React, { useContext } from 'react';
import {HashRouter as Router,Routes,Route, Navigate} from 'react-router-dom'
import Home from './pages/Home/Home';
// import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import About from './pages/about/About'
import Events from './pages/events/Events';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import EventForm from './pages/eventForm/EventForm';
import { AuthContext } from './context/AuthContext';
import EventDetails from './pages/eventDetails/EventDetails';
import ForgotPassword from './pages/forgetpassword/ForgotPassword';
import ResetPassword from './pages/resetpassword/ResetPassword';
import Component from './Component';
import { AdminContext } from './context/AdminContext';
import AdminLogin from './pages/admin/AdminLogin';
import TermofSerivices from './pages/TermofSerivices';


function App() {

  const {user}=useContext(AuthContext);
  

  const ProtectedRoute = ({ children }) => {
    const {adminUser}=useContext(AdminContext)

    if (!adminUser) {
      return <Navigate to="/login" />;
    }

    return children;
  };


  return (
    <Router>
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/about"  element={<About />} />
      <Route path="/component"  element={<ProtectedRoute><Component /></ProtectedRoute>} />
      <Route path="/adminlogin" element={<AdminLogin />} />
      <Route path="/termpolicy" element={<TermofSerivices />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/events" element={<Events />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/eventform" element={user ? <EventForm /> : <Navigate to="/login" />} />
      <Route path="/eventdetails" element={user ? <EventDetails /> : <Navigate to="/login" />} />
    </Routes>
    <Footer />
  </Router>
  );
}

export default App;
