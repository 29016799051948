import React, { useContext, useRef, useState } from 'react';
import './Login.css';
import One from '../../assests/home/one.webp'
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import Header from '../../components/header/Header';

const Login = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const [checked, setChecked] = useState(false);
    const [loadings, setLoadings] = useState(false);

    const { loading, error, dispatch } = useContext(AuthContext);
    const [errors, setErrors] = useState("")

    const navigate = useNavigate()
    const handleToggle = () => {
        if (type === 'password') {
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }

    const handleChange = () => {
        setChecked(!checked);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch({ type: "LOGIN_START" });
        setLoadings(true);
        const emailValue = emailRef.current.value.toLowerCase();
        console.log(emailValue)
        try {
            const res = await axios.post("https://back-ram.onrender.com/api/auth/login", {
                email: emailValue,
                password: passwordRef.current.value,
            });
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
            setLoadings(false)
            alert("Login Succesfull");
            res.data &&
                navigate('/events')
        } catch (error) {
            dispatch({ type: "LOGIN_FAILURE" });
            setLoadings(false)
            setErrors({ message: error.response.data.message });
            { errors && alert(errors.message) }
        }
        setLoadings(false);
    };


    return (
        <>
            {loadings && <div class="preloader"></div>}
            <Header />
            <div className='loginmain'>
                <div className='container-fluid col-10'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-6 col-12'>
                            <img src={One} className='img-fluid oneLogin_images center-block d-block mx-auto mt-5' alt="One Images" width='500' height='450' loading='lazy' />
                            <h3 className='banner_head mt-4'>
                                UNDERGRADUATE PROSTHODONTIC
                            </h3>
                            <h2 className='banner_highHead'>
                                CONVENTION
                            </h2>
                            <div className='white_back mt-4'>
                                <p className='banner_date'>
                                    22nd & 23rd March 2024, Chennai
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 mt-lg-0 mt-5'>
                            <h3 className='loguinHead'>
                                Login
                            </h3>
                            <p className='login_parra mt-4'>
                                Welcome to the 1st UG Prosthodontic Convention login page. Please enter your login credentials to access your account:
                            </p>

                            <div className='d-flex justify-content-lg-start justify-content-center align-items-center mt-lg-0 mt-5'>
                                <div className=''>
                                    <Link to={'/login'} className='loginanchor_parra'>
                                        User
                                    </Link>
                                </div>
                                <div className='mx-4 loginanchor_parra'>
                                    |
                                </div>
                                <div className=''>
                                    <Link to={'/adminlogin'} className='loginanchor_parra'>
                                        Admin
                                    </Link>
                                </div>
                            </div>

                            <form onSubmit={handleSubmit}>
                                <div className='input_login mt-lg-4 mt-5'>
                                    <input type='email' ref={emailRef} className='loginsInput' placeholder='Enter Your Email' style={{ textTransform: "lowercase" }} />
                                </div>
                                <div className='input_login mt-4'>
                                    <input
                                        type={type}
                                        name="password"
                                        ref={passwordRef}
                                        placeholder="Password"
                                        className='loginsInput'
                                        autoComplete="current-password"
                                    />
                                    <span class="iconsabsolute" onClick={handleToggle}>
                                        <Icon class="absolute mr-10" icon={icon} size={25} />
                                    </span>

                                    <Link to={'/forgotpassword'} className='forgot_password'>
                                        Forgot Password
                                    </Link>

                                    <div className='Icons_module mt-5'>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            {/* <div className='absolute_iconsLogin'>
                            <Icon icon={info} className='absolute' size={10} />
                            </div> */}
                                            <div>
                                                <p className='mb-0 mx-3 infographics_parra'>Info :-</p>
                                            </div>

                                        </div>

                                        <ul className='mt-2'>
                                            <li className='loginListParra'>
                                                Student can only enroll in 1 curricular and 1 co-curricular activity.
                                            </li>
                                            <li className='loginListParra'>
                                                Only First author submits abstract for the event.
                                            </li>
                                            <li className='loginListParra'>
                                                Both the authors and co-authors should be registered to participate in the events.
                                            </li>
                                        </ul>



                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12 col-12'>
                                            <div className='d-flex justify-content-start align-items-center mt-4'>
                                                <div>
                                                    <input type='checkbox' className='mb-0' value={checked} onChange={handleChange} />
                                                </div>
                                                <div className='mx-3'>
                                                    <p className='termlogin_parra mb-1'>
                                                        I accept all the terms and conditions
                                                    </p>
                                                </div>
                                            </div>

                                            <button className='buttonlogin_events mt-4 center-block d-block mx-lg-0 mx-auto mb-5' disabled={checked ? false : true}>
                                                Login
                                            </button>
                                        </div>
                                    </div>

                                    {/* <input type='password' placeholder='Enter Your Password' /> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login