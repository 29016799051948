import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import './EventsFormRegistration.css'
import { AuthContext } from '../../context/AuthContext';

const EventsValid = () => {

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [change, setChange] = useState('');
  const [change1, setChange1] = useState('');
  const [change2, setChange2] = useState('');
  const [change3, setChange3] = useState('');
  const [change4, setChange4] = useState('');
  const [findUser, setFindUser] = useState([]);
  const [findUser1, setFindUser1] = useState([]);
  const [findUser2, setFindUser2] = useState([]);
  const [findUser3, setFindUser3] = useState([]);
  const [findUser4, setFindUser4] = useState([]);
  const [authorId, setauthorId] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [presentation, setPresentation] = useState('');
  const [abstract, setAbstract] = useState('');
  const [activities, setActivities] = useState('')
  const [cocircularActivities, setCocircularActivities] = useState('');
  const [modals, setModals] = useState(false)
  const [errors, setErrors] = useState("");
  const [mainCategory, setMainCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [title, setTitle] = useState("");
  const [loadings, setLoadings] = useState(false);

  const [getAllUser, setGetAllUser] = useState([]);

  const [checked, setChecked] = useState(false);



  const handleChange = () => {
    setChecked(!checked);
  }

  useEffect(() => {
    const VerifyUser = async () => {
      try {
        const responses = await axios.get(`https://back-ram.onrender.com/api/auth/getAllUser`);

        setGetAllUser(responses.data);

      } catch (err) {

      }
    };
    VerifyUser();
  }, []);


  const emailOptions = getAllUser
    .filter((users) => user && user.details.email && users.email && users.email !== user.details.email)
    .map((users) => ({
      value: users.email,
      label: users.email,
    }));

  const handlePresentation = (value) => {
    setPresentation(value);
  };

  const [coAuthors, setCoAuthors] = useState([{ email: null }]);
  const [coCircularAuthors, setCoCircularAuthors] = useState([{ email: null }]);



  const handleMainCategory = (value) => {
    setMainCategory(value);
  };

  const handleSubCategory = (value) => {
    setSubCategory(value);
  };
  const handleCoActivities = (value) => {
    setCocircularActivities(value);
  };




  useEffect(() => {
    setauthorId(user.details.authorId);
  }, [user]);




  const handleSelectChange = (index, selectedOption) => {
    const newInputFields = [...coAuthors];
    newInputFields[index] = { email: selectedOption.value };
    setCoAuthors(newInputFields);
  };


  const handleAddField = () => {
    setCoAuthors([...coAuthors, { email: '' }]);
  };


  const handleRemoveField = (index) => {
    const newInputFields = [...coAuthors];
    newInputFields.splice(index, 1);
    setCoAuthors(newInputFields);
  };


  const handleSelectChanges = (index, selectedOption) => {
    const newInputFields = [...coCircularAuthors];
    newInputFields[index] = { email: selectedOption.value };
    setCoCircularAuthors(newInputFields);
  };



  const handleAddFields = () => {
    setCoCircularAuthors([...coCircularAuthors, { email: '' }]);
  };
  const handleRemoveFields = (index) => {
    const newInputFields = [...coCircularAuthors];
    newInputFields.splice(index, 1);
    setCoCircularAuthors(newInputFields);
  };


  useEffect(() => {
    const VerifyUser = async () => {
      try {
        const responses = await axios.get(`https://back-ram.onrender.com/api/auth/get?user=${change}`);
        setFindUser(responses.data);

      } catch (err) {

      }
    };
    VerifyUser();
  }, [change]);



  useEffect(() => {
    const VerifyUser = async () => {
      try {
        const responses = await axios.get(`https://back-ram.onrender.com/api/auth/get?user=${change1}`);
        setFindUser1(responses.data);
      } catch (err) {

      }
    };
    VerifyUser();
  }, [change1]);

  useEffect(() => {
    const VerifyUser = async () => {
      try {
        const responses = await axios.get(`https://back-ram.onrender.com/api/auth/get?user=${change2}`);
        setFindUser2(responses.data);
      } catch (err) {

      }
    };
    VerifyUser();
  }, [change2]);

  useEffect(() => {
    const VerifyUser = async () => {
      try {
        const responses = await axios.get(`https://back-ram.onrender.com/api/auth/get?user=${change3}`);
        setFindUser3(responses.data);
      } catch (err) {

      }
    };
    VerifyUser();
  }, [change3]);

  useEffect(() => {
    const VerifyUser = async () => {
      try {
        const responses = await axios.get(`https://back-ram.onrender.com/api/auth/get?user=${change4}`);
        setFindUser4(responses.data);
      } catch (err) {

      }
    };
    VerifyUser();
  }, [change4]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadings(true);

    //   if ((presentation === "" || cocircularActivities === "" )) {
    //     setLoadings(false);
    //     alert("Please Enter Scientific and Cocurricular Category");
    //     return;
    // }

    // if(activities===""){
    //   setLoadings(false);
    //   alert("Please Select The Year");
    //   return;
    // }

    if ((presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && mainCategory === "") {
      setLoadings(false);
      alert("Please Select Main Category");
      return;
    }

    if ((presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && subCategory === "") {
      setLoadings(false);
      alert("Please Select Sub Category");
      return;
    }

    if ((presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && abstract === "") {
      setLoadings(false);
      alert("Please Enter Abstract");
      return;
    }

    if ((presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && title === "") {
      setLoadings(false);
      alert("Please Enter Title of Presentation");
      return;
    }

    // if (
    //   (presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") &&
    //   coAuthors.some(author => author.email === null )
    // ) {
    //   setLoadings(false);
    //   alert("Please Enter Scientific Co-Author Details");
    //   return;
    // }

    // if (
    //   (cocircularActivities === "Video Presentation" || cocircularActivities === "Meme") &&
    //   coCircularAuthors.some(author => author.email === null )
    // ) {
    //   setLoadings(false);
    //   alert("Please Enter Co-Curricular Co-Author Details");
    //   return;
    // }
    const newDetails = {
      authorId: authorId,
      presentation,
      activities,
      cocircularActivities,
      abstract,
      subCategory,
      mainCategory,
      isSuccess,
      title,
      coAuthor: coAuthors,
      coCircularAuthor: coCircularAuthors,
    };
    try {
      const response = await axios.post('https://back-ram.onrender.com/api/post/postDetails', newDetails);
      setLoadings(false);
      response.data && navigate('/eventdetails')
      alert('Poster is Added');
    } catch (error) {
      setLoadings(false);

      setErrors({ message: error.response.data.message });

    }
    setLoadings(false);
  };

  useEffect(() => {
    { errors && alert(errors.message) }
  }, [errors])
  // useEffect(()=>{
  //   coAuthors[0]&&setChange(coAuthors[0].email);
  //   coAuthors[1]&&setChange1(coAuthors[1].email);
  //   coAuthors[2]&&setChange2(coAuthors[2].email);
  //   coCircularAuthors[0]&&setChange3(coCircularAuthors[0].email);
  //   coCircularAuthors[1]&&setChange4(coCircularAuthors[1].email);
  // });
  useEffect(() => {
    coAuthors[0] && setChange(coAuthors[0].email);
    coAuthors[1] && setChange1(coAuthors[1].email);
    coAuthors[2] && setChange2(coAuthors[2].email);
  }, [coAuthors]);

  useEffect(() => {
    coCircularAuthors[0] && setChange3(coCircularAuthors[0].email);
    coCircularAuthors[1] && setChange4(coCircularAuthors[1].email);
  }, [coCircularAuthors]);

  const handleNavigate = () => {
    navigate('/UserDetails')
  }

  const handlecheckModal = () => {
    setModals(!modals);
  };

  return (

    <>

      {loadings && <div class="preloader"></div>}
      <div>

        <form onSubmit={handleSubmit} className='mt-5'>
          <div className='container-fluid col-10'>
            <div className='row'>
              <div className='col-lg-3'>

                <div className=''>
                  <h3 className='eventsRegisterHead'>
                    Scientific <span className='selectedEventsRegister'>(Any one to be selected)</span><span className='star_plus'>*</span>

                  </h3>
                  <div className='mt-3'>
                    <input type='radio' className='input_checkedevents' id='Poster Presentation' value="Poster Presentation" checked={presentation === "Poster Presentation"}
                      onChange={() => { handlePresentation("Poster Presentation") }} />
                    <label htmlFor='Poster Presentation' className='inputchecked_parra mx-2'>Poster Presentation</label>

                  </div>
                  <div>
                    <input type='radio' className='input_checkedevents' id='Paper Presentation' value="Paper Presentation" checked={presentation === "Paper Presentation"}
                      onChange={() => { handlePresentation("Paper Presentation") }} />
                    <label htmlFor='Paper Presentation' className='inputchecked_parra mx-2'>Paper Presentation</label>

                  </div>
                  <div>
                    <input type='radio' className='input_checkedevents' id='Table Clinic' value="Table Clinic" checked={presentation === "Table Clinic"}
                      onChange={() => { handlePresentation("Table Clinic") }} />
                    <label htmlFor='Table Clinic' className='inputchecked_parra mx-2'>Table Clinic</label>

                  </div>
                  <div>
                    <input type='radio' className='input_checkedevents' id='Teeth Arrangement' value="Teeth Arrangement" checked={presentation === "Teeth Arrangement"}
                      onChange={() => { handlePresentation("Teeth Arrangement") }} />
                    <label htmlFor='Teeth Arrangement' className='inputchecked_parra mx-2'>Teeth Arrangement</label>

                  </div>
                  <div>
                    <input type='radio' className='input_checkedevents' id='Tooth Preparation' value="Tooth Preparation" checked={presentation === "Tooth Preparation"}
                      onChange={() => { handlePresentation("Tooth Preparation") }} />
                    <label htmlFor='Tooth Preparation' className='inputchecked_parra mx-2'>Tooth Preparation</label>

                  </div>
                </div>

                <div className='mt-4'>
                  {presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic" ? (
                    <select className='eventsinput_border' onChange={(e) => setActivities(e.target.value)}>
                      <option value="">Select a Year</option>
                      <option value="I Year">I Year</option>
                      <option value="II Year">II Year</option>
                      <option value="III Year">III Year</option>
                      <option value="IV Year">IV Year</option>
                      <option value="Intern">Intern</option>
                    </select>
                  ) : ""}

                  {presentation === "Teeth Arrangement" ? (
                    <select className='eventsinput_border' onChange={(e) => setActivities(e.target.value)}>
                      <option value="">Select a Year</option>
                      <option value="II Year">II Year</option>
                      <option value="III Year">III Year</option>
                    </select>
                  ) : ""}

                  {presentation === "Tooth Preparation" ? (
                    <select className='eventsinput_border' onChange={(e) => setActivities(e.target.value)}>
                      <option value="">Select a Year</option>
                      <option value="IV Year">IV Year</option>
                      <option value="Intern">Intern</option>
                    </select>
                  ) : ""}
                </div>
              </div>




              <div className='col-lg-9 col-12 mt-lg-0 mt-5'>


                {(presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && (

                  <>
                    <div className='eventsinput__details'>

                      <label>
                        <h3 className='eventsRegisterHead'>Scientific Co - Author Registration</h3>
                      </label>
                    </div>

                    {coAuthors.map((field, index) => (
                      <div key={index} className='mt-4'>
                        <div >
                          <div className='eventsinput__details'>

                            <Select
                              placeholder="Enter Co-Author Email Id..."
                              value={{ value: field.email, label: field.email }}
                              onChange={(selectedOption) => handleSelectChange(index, selectedOption)}
                              options={emailOptions}
                              required={(cocircularActivities === "Video Presentation" || cocircularActivities === "Meme") && true}
                            />

                            <div className='d-flex justify-content-start align-items-center mt-2'>

                              {coAuthors.length > 1 && (
                                <button type="button" className='removeButtoncoAuthor mx-2' onClick={() => handleRemoveField(index)}>
                                  <i class="bi bi-trash3 mx-2"></i> Remove
                                </button>
                              )}

                              {(presentation === "Poster Presentation" || presentation === "Paper Presentation") && coAuthors.length < 1 ? (
                                <button type="button" className='addButtoncoAuthor' onClick={handleAddField}>
                                  Add More<i class="bi bi-plus-circle mx-2"></i>
                                </button>
                              ) : null}
                              {(presentation === "Table Clinic") && coAuthors.length < 3 ? (
                                <button type="button" className='addButtoncoAuthor' onClick={handleAddField}>
                                  Add More<i class="bi bi-plus-circle mx-2"></i>
                                </button>
                              ) : null}
                            </div>

                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {findUser && (
                  <div className='col-12'>
                    <h3 className='eventsRegisterHead1 mt-3 text-center'>User Details:-</h3>
                  </div>
                )}
                <div className='row eventsinput__details1 d-flex justify-content-center align-items-center'>
                  {(presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && (
                    <div className='col-lg-6 col-12 '>
                      {findUser &&
                        findUser.map((paras) => {
                          return (
                            <div key={paras._id}>

                              <div className='markerdetails right-block d-block ms-auto'>


                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.username} disabled />

                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.collegename} disabled />
                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.PhoneNumber} disabled />
                              </div>

                            </div>
                          );
                        })}
                    </div>
                  )}

                  {presentation === "Table Clinic" && (
                    <div className='col-lg-6 col-12 mt-lg-0 mt-5'>
                      {findUser1 &&
                        findUser1.map((paras) => {
                          return (
                            <div key={paras._id}>

                              <div className='markerdetails right-block d-block ms-auto'>


                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.username} disabled />

                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.collegename} disabled />
                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.PhoneNumber} disabled />
                              </div>

                            </div>
                          );
                        })}
                    </div>
                  )}

                  {presentation === "Table Clinic" && (
                    <div className='col-lg-6 col-12 mt-4'>
                      {findUser2 &&
                        findUser2.map((paras) => {
                          return (
                            <div key={paras._id}>

                              <div className=' markerdetails'>


                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.username} disabled />

                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.collegename} disabled />
                                <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.PhoneNumber} disabled />
                              </div>

                            </div>
                          );
                        })}
                    </div>
                  )}

                  <div className='col-lg-6'></div>

                </div>
              </div>
            </div>





            <div className='row'>
              <div className='col-lg-3 col-12 mt-5'>
                <div className=''>
                  <div className=''>
                    <div className='d-flex justify-content-start align-items-center'>
                      <input
                        type="checkbox"
                        checked={modals}
                        onChange={handlecheckModal}
                      />
                      <h3 className='eventsRegisterHead mx-2'>
                        Co - Curricular <span className='selectedEventsRegister'>(Any one to be selected)</span>

                      </h3>
                    </div>
                  </div>

                </div>





                {modals && (
                  <div className='col-lg-12 mt-2'>
                    <div className=''>
                      <div>
                        <input type='radio' id='Video Presentation' value="Video Presentation" checked={cocircularActivities === "Video Presentation"}
                          onChange={() => { handleCoActivities("Video Presentation") }} />
                        <label htmlFor='Video Presentation' className='inputchecked_parra mx-2'>Video Presentation </label>

                      </div>
                      <div>
                        <input type='radio' id='Meme' value="Meme" checked={cocircularActivities === "Meme"}
                          onChange={() => { handleCoActivities("Meme") }} />
                        <label htmlFor='Meme' className='inputchecked_parra mx-2'>Meme</label>

                      </div>
                      <div>
                        <input type='radio' id='Dental Photography' value="Dental Photography" checked={cocircularActivities === "Dental Photography"}
                          onChange={() => { handleCoActivities("Dental Photography") }} />
                        <label htmlFor='Dental Photography' className='inputchecked_parra mx-2'>Dental Photography</label>

                      </div>
                    </div>
                  </div>

                )}

              </div>


              <div className='col-lg-9 col-12 mt-5'>


                {(cocircularActivities === "Video Presentation" || cocircularActivities === "Meme") && (

                  <>
                    <div className='eventsinput__details'>
                      <label>
                        <h3 className='eventsRegisterHead'>Co - Curricular Co-Author Registration</h3>
                      </label>
                    </div>
                    {coCircularAuthors.map((field, index) => (
                      <div key={index}>
                        <div className=' mt-4'>
                          <div className='eventsinput__details'>
                            <Select
                              value={{ value: field.email, label: field.email }}
                              onChange={(selectedOption) => handleSelectChanges(index, selectedOption)}
                              placeholder="Enter a Co-Author Email Id"
                              options={emailOptions}
                            />

                            <div className='d-flex justify-content-start align-items-center mt-2'>
                              {coCircularAuthors.length > 1 && (
                                <button type="button" className='removeButtoncoAuthor mx-2' onClick={() => handleRemoveFields(index)}>
                                  <i class="bi bi-trash3 mx-2"></i>Remove
                                </button>
                              )}
                              {coCircularAuthors.length < 1 ? (
                                <button type="button" className='addButtoncoAuthor' onClick={handleAddFields}>
                                  Add More<i class="bi bi-plus-circle mx-2"></i>
                                </button>
                              ) : null}
                              {coCircularAuthors.length < 2 ? (
                                <button type="button" className='addButtoncoAuthor' onClick={handleAddFields}>
                                  Add More<i class="bi bi-plus-circle mx-2"></i>
                                </button>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  </>
                )}

                {(cocircularActivities === "Video Presentation" || cocircularActivities === "Meme") && (
                  <>

                    {findUser3 && (
                      <div className='col-12'>
                        <h3 className='eventsRegisterHead1 mt-3 text-center'>User Details:-</h3>
                      </div>
                    )}
                    <div className='row eventsinput__details1 d-flex justify-content-center align-items-center'>
                      <div className='col-lg-6 col-12 '>
                        {findUser3 &&
                          findUser3.map((paras) => {
                            return (
                              <div key={paras._id}>

                                <div className=' markerdetails'>


                                  <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.username} disabled />

                                  <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.collegename} disabled />
                                  <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.PhoneNumber} disabled />
                                </div>

                              </div>
                            );
                          })}
                      </div>

                      <div className='col-lg-6 col-12 mt-lg-0 mt-5'>
                        {findUser4 &&
                          findUser4.map((paras) => {
                            return (
                              <div key={paras._id}>

                                <div className=' markerdetails'>


                                  <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.username} disabled />

                                  <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.collegename} disabled />
                                  <input type='text' className='eventsinput_border right-block d-block ms-lg-auto' defaultValue={paras.PhoneNumber} disabled />
                                </div>

                              </div>
                            );
                          })}
                      </div>


                    </div>
                  </>

                )}

              </div>
            </div>

            <div className='row'>
              <div className='col-lg-6 col-12 mt-2 mt-5'>
                <div className=''>
                  {(presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && (
                    <div>
                      <h3 className='eventsRegisterHead'>
                        Main Category <span className='selectedEventsRegister'>(Any one to be selected)</span><span className='star_plus'>*</span>

                      </h3>
                      <div className='mt-4'>
                        <input type='radio' id='Removable Prosthodontics' value="Removable Prosthodontics" checked={mainCategory === "Removable Prosthodontics"}
                          onChange={() => { handleMainCategory("Removable Prosthodontics") }} />
                        <label htmlFor='Removable Prosthodontics' className='inputchecked_parra mx-2' >Removable Prosthodontics</label>
                      </div>
                      <div>
                        <input type='radio' id='Tooth supported Fixed Prosthodontics' value="Tooth supported Fixed Prosthodontics" checked={mainCategory === "Tooth supported Fixed Prosthodontics"}
                          onChange={() => { handleMainCategory("Tooth supported Fixed Prosthodontics") }} />
                        <label htmlFor='Tooth supported Fixed Prosthodontics' className='inputchecked_parra mx-2'>Tooth supported Fixed Prosthodontics</label>
                      </div>
                      <div>
                        <input type='radio' id='Maxillo-Facial Prosthodontics' value="Maxillo-Facial Prosthodontics" checked={mainCategory === "Maxillo-Facial Prosthodontics "}
                          onChange={() => { handleMainCategory("Maxillo-Facial Prosthodontics ") }} />
                        <label htmlFor='Maxillo-Facial Prosthodontics' className='inputchecked_parra mx-2'>Maxillo-Facial Prosthodontics </label>
                      </div>
                      <div>
                        <input type='radio' id='Implant Prosthodontics' value="Implant Prosthodontics" checked={mainCategory === "Implant Prosthodontics"}
                          onChange={() => { handleMainCategory("Implant Prosthodontics") }} />
                        <label htmlFor='Implant Prosthodontics' className='inputchecked_parra mx-2'>Implant Prosthodontics</label>
                      </div>
                      <div>
                        <input type='radio' id='Dental Materials' value="Dental Materials" checked={mainCategory === "Dental Materials"}
                          onChange={() => { handleMainCategory("Dental Materials") }} />
                        <label htmlFor='Dental Materials' className='inputchecked_parra mx-2'>Dental Materials</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='col-lg-6 col-12 mt-2 mt-5'>
                <div className=''>
                  {(presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && (
                    <div>
                      <h3 className='eventsRegisterHead'>
                        Sub Category <span className='selectedEventsRegister'>(Any one to be selected)</span><span className='star_plus'>*</span>

                      </h3>
                      <div className='mt-4'>
                        <input type='radio' id='Original Research' value="Original Research" checked={subCategory === "Original Research"}
                          onChange={() => { handleSubCategory("Original Research") }} />
                        <label htmlFor='Original Research' className='inputchecked_parra mx-2'>Original Research</label>
                      </div>
                      <div>
                        <input type='radio' id='Case Report' value="Case Report" checked={subCategory === "Case Report"}
                          onChange={() => { handleSubCategory("Case Report") }} />
                        <label htmlFor='Case Report' className='inputchecked_parra mx-2'>Case Report</label>
                      </div>
                      <div>
                        <input type='radio' id='Literature Review' value="Literature Review" checked={subCategory === "Literature Review"}
                          onChange={() => { handleSubCategory("Literature Review") }} />
                        <label htmlFor='Literature Review' className='inputchecked_parra mx-2'>Literature Review</label>
                      </div>

                    </div>
                  )}
                </div>
              </div>
            </div>

            {(presentation === "Poster Presentation" || presentation === "Paper Presentation" || presentation === "Table Clinic") && (
              <div className='row'>
                <div className='col-lg-6 col-12 mt-5'>
                  <h3 className='eventsRegisterHead'>
                    Title of Presentation<span className='star_plus'>*</span></h3>
                  <input className='eventsinput_border' placeholder='Title of Presentation' onChange={(e) => setTitle(e.target.value)} />
                </div>
                <div className='col-lg-6 col-12 mt-2 mt-5'>
                  <div className=''>

                    <textarea type='text' maxLength={6000} className='textareaevents_login  mt-4' placeholder='Abstract' onChange={(e) => setAbstract(e.target.value)}></textarea>
                  </div>
                </div>
              </div>
            )}



            <div className='row'>
              <div className='col-lg-12 col-12'>
                <div className='eventsinput__details d-flex justify-content-start align-items-center mt-5'>
                  <div>
                    <input type='checkbox' className='mb-0' value={checked} onChange={handleChange} />
                  </div>
                  <div className='mx-3'>
                    <p className='term_parra mb-1'>
                      I accept all the terms and conditions
                    </p>
                  </div>
                </div>


              </div>
            </div>

            <div className='col-lg-12 col-12'>
              <button type='submit' className='button_events1 mt-4 center-block d-block mx-auto mt-4' disabled={checked ? false : true}>
                Send
              </button>

            </div>

          </div>

        </form>

      </div>


    </>

  );
};


export default EventsValid;
