import React from 'react';
import './RegisterContent.css'

const RegisterContent = () => {
  return (
    <div className='registerMain'>
        <div className='container-fluid col-10'>
            <div className='row'>
                <div className='col-lg-12 col-12'>
                <h2 className='register_parra'>
        REGISTRATION FOR 1st UNDERGRADUATE PROSTHODONTIC CONVENTION
        </h2>
                </div>
            </div>
        </div>
       
    </div>
  )
}

export default RegisterContent