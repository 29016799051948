import React, { useState } from 'react';
import axios from "axios";
import Header from '../../components/header/Header';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            setMessage("");
            setError("");
            const res = await axios.post("https://back-ram.onrender.com/api/auth/forgotPassword", { email })

            setMessage(res.data.message);

        } catch (error) {
            setError(error.response.data.message)

        } finally {
            setIsLoading(false)
        }
    }
    return (
        <div >
            <Header />
            <div className='eventformmain'>
                <h3 className='eventformhead'>
                    Forgot Password
                </h3>
            </div>
            <form onSubmit={handleSubmit}>
                <div className='container-fluid mt-5'>
                    <div className='row'>
                        <div className='col-lg-12 col-12'>
                            <div className='registerinput__details'>
                                <h3 className='eventsRegisterHead mt-3'>Email:-</h3>
                                <input type="email"
                                    className='registerInput'
                                    placeholder="Enter your email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />

                                <button className='button_events mt-4 center-block d-block mx-auto mt-4' disabled={isLoading}>Reset</button>

                            </div>
                        </div>
                    </div>
                </div>

            </form>
            {
                message && <div >
                    <p className='text-center mt-4' style={{ color: "green" }}>
                        {message}
                    </p>
                </div>
            }
            {
                error && <div >
                    <p className='text-center mt-4' style={{ color: "red" }}>
                        {error}
                    </p>
                </div>
            }
        </div>
    );
}

export default ForgotPassword;