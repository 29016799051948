import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext';
import './EventDetails.css'
import Header from '../../components/header/Header';

const EventDetails = () => {
    const { user } = useContext(AuthContext);

  const [userPost, setUserPost] = useState(false);
  const [posterDetails, setPosterDetails] = useState({}); 

  useEffect(() => {
    try {
      const fetchUserPost = async () => {
        const response = await axios.get('https://back-ram.onrender.com/api/post/getPost');
        if (response && Array.isArray(response.data)) {
          const authorIdsArray = response.data.map((post) => post.authorId);
          const specificAuthorId = user.details.authorId;
          const isAuthorIdPresent = authorIdsArray.includes(specificAuthorId);
          setUserPost(isAuthorIdPresent);
        }
      };

      fetchUserPost();
    } catch (error) {
      console.error('Error fetching post details:', error);
    }
  }, [user.details.authorId]);

  useEffect(() => {
    const verifyDetails = async () => {
      try {
        const response = userPost
          ? await axios.get(`https://back-ram.onrender.com/api/post/getPost?authorId=${user.details.authorId}`)
          : await axios.get(`https://back-ram.onrender.com/api/post/getPost?email=${user.details.email}`);
        
        setPosterDetails(response.data || {});
        console.log(posterDetails)
        // Set to an empty object if response.data is falsy
      } catch (error) {
        console.error('Error fetching poster details:', error);
      }
    };

    verifyDetails();
  }, [userPost, user.details.authorId]);
  return (
    <>
        <Header />
        <div className='eventformmain'>
    <h3 className='eventformhead'>
        Events Details
    </h3>
    </div>
        <div>
    
        <div className='container-fluid col-10 eventDetails mt-5'>
            <div className='row'>
                <div className='col-lg-12'>
         {posterDetails.title &&  <h2 className='guidelines_head mt-lg-5 mt-4'>{posterDetails.title}</h2>}   
       {posterDetails.presentation  &&  <p className='mt-3'><b>Scientific</b>: {posterDetails.presentation}</p>}  
       {posterDetails.activities &&  <p><b>Year</b>: {posterDetails.activities}</p>}  
       {posterDetails.cocircularActivities && <p><b>Co-Circular</b>: {posterDetails.cocircularActivities}</p>}   
        {posterDetails.mainCategory && <p><b>Main Category</b>: {posterDetails.mainCategory}</p>}  
         {posterDetails.subCategory && <p><b>Sub Category</b>: {posterDetails.subCategory}</p>} 
         {posterDetails.abstract &&  <p><b>Abstract</b>: {posterDetails.abstract}</p>}
          </div>
          </div>
        </div>
 
    </div>
    </>
  )
}

export default EventDetails