import React, { useContext, useEffect, useState } from 'react';
import './EventsSplit.css'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

const EventsSplit = () => {
    const { user } = useContext(AuthContext);

    const [userPost, setUserPost] = useState(false);
    const [posterDetails, setPosterDetails] = useState({});
    const [toggle, setToggle] = useState(1);
    const [checked, setChecked] = useState(false);

    const handleToggle = (index) => {
        setToggle(index);
    };

    const handleChange = () => {
        setChecked(!checked);
    };

    useEffect(() => {
        if (user && user.details) {
            try {
                const fetchUserPost = async () => {
                    const response = await axios.get('https://back-ram.onrender.com/api/post/getPost');
                    if (response && Array.isArray(response.data)) {
                        const authorIdsArray = response.data.map((post) => post.authorId);
                        const specificAuthorId = user.details.authorId;
                        const isAuthorIdPresent = authorIdsArray.includes(specificAuthorId);
                        setUserPost(isAuthorIdPresent);
                    }
                };

                fetchUserPost();
            } catch (error) {
                console.error('Error fetching post details:', error);
            }
        }
    }, [user?.details?.authorId, user]);

    useEffect(() => {
        const verifyDetails = async () => {
            try {
                const response = userPost
                    ? await axios.get(`https://back-ram.onrender.com/api/post/getPost?authorId=${user?.details?.authorId}`)
                    : await axios.get(`https://back-ram.onrender.com/api/post/getPost?email=${user?.details?.email}`);

                setPosterDetails(response.data || {});
              

            } catch (error) {
                console.error('Error fetching poster details:', error);
            }
        };

        verifyDetails();
    }, [userPost, user?.details?.authorId, user?.details?.email, posterDetails, user]);

    return (
        <div className='container-fluid col-lg-10 col-12'>
            <div className='row'>
                <div className='col-lg-12 col-12'>
                    <h3 className='ugHead text-center'>
                        1st UG PROSTHODONTIC CONVENTION
                    </h3>
                    <div className='row mt-5 mb-5'>
                        <div className='col-lg-3 col-6'>
                            <button className="tabs active-tabs">
                                General Guidelines
                            </button>
                        </div>

                        <div className="content active-content">
                            <h3 className='guidelines_head mt-lg-5 mt-4'>General Guidelines </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    All presenters must register for the conference, otherwise their abstracts will not be considered.
                                </li>
                                <li className='eventsList_parra'>
                                    The delegate should mention the category and type of presentation:

                                </li>
                                <li className='eventsList_parra'>
                                    Scientific category- Oral/Poster/Table/Teeth arrangement/Tooth preparation

                                </li>
                                <li className='eventsList_parra'>
                                    Cocurricular category- Meme creation/Video presentation/Dental photography

                                </li>
                                <li className='eventsList_parra'>
                                    A delegate can present <b>only in ONE type of presentation under each category</b>. They cannot be primary/co-authors for more than one type of presentation in a category.

                                </li>
                                <li className='eventsList_parra'>
                                    If participating in <b>both scientific and co-curricular category, kindly register simultaneously</b>. The website will not accept separate registration

                                </li>
                                <li className='eventsList_parra'>
                                    A maximum of 2 authors can be there for the Paper and poster presentation, and a maximum of 4 authors for the Table-Clinic presentation.

                                </li>
                                <li className='eventsList_parra'>
                                    A maximum of 2 authors each for meme creation and video presentation

                                </li>
                                <li className='eventsList_parra'>
                                    There are no co-authors for teeth arrangment, tooth preparation and dental photography

                                </li>
                                <li className='eventsList_parra'>
                                    For teeth arrangements and tooth preparation alone, only maximum of 4 participants will be allowed from each event.

                                </li>
                                <li className='eventsList_parra'>
                                    Prepare your abstract for Paper, poster, table clinic.

                                </li>
                                <li className='eventsList_parra'>
                                    <b>Registration for scientific and co-curricular event should be done before 11th March 2024</b>
                                </li>

                                <li className='eventsList_parra'>
                                    Delegates should <b>only mention their registration numbers on the presentation</b>. They should not mention their names, guide names, and the institution anywhere in the presentation. If found, their presentation will be rejected.

                                </li>
                                <li className='eventsList_parra'>
                                    <b>Rename</b> your presentation <b>file</b> as your <b>registration number</b>., For eg., 2024cc1

                                </li>
                                <li className='eventsList_parra'>
                                    All presentation abstracts should be attested with the signature and seal of the Head of Department.

                                </li>
                                <li className='eventsList_parra'>
                                    All the presentations for co-curricular category  are to be uploaded at least two days in advance before the <b>last date of submission (March 14th 2024)</b> to avoid last-minute hassle.

                                </li>
                                <li className='eventsList_parra'>
                                    Presentations received after the last date of submission will not be considered.

                                </li>
                                <li className='eventsList_parra'>
                                    No modifications will be entertained once submitted. (Please read the individual category guidelines)

                                </li>
                                <li className='eventsList_parra'>
                                    Both Author and Co-authors should be present in the Hall <b>one hour before</b> in the mentioned timing. Kindly check website after 15th March 2024 for the schedule

                                </li>
                                <li className='eventsList_parra'>
                                    <b>
                                        Kindly join the Whatsapp group sent to your registered mail ID. This wil enable you to receive timely update
                                    </b>

                                </li>
                                <li className='eventsList_parra'>
                                    <b>Overall award </b>will be given to the Institute with maximum session best winners

                                </li>
                                <li className='eventsList_parra'>
                                    All participating students should register. The faculties accompanying students will be levied of convention registration. One faculty for minimum of 10 students and no more than 2 faculty per college will be allowed. The accompanying faculty should mail their details along with list of students on or before 15th March 2024 to 1stugipscon@gmail.com with the following details<br />
                                    Name of faculty/faculties:<br />
                                    IPS OL registration no:<br />
                                    Designation:<br />
                                    Institute:<br />
                                    City, State:<br />
                                    Number of students:<br />
                                </li>

                            </ul>

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-6'>
                            <button className={toggle === 1 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(1)}>
                                Poster Presentation
                            </button>
                        </div>
                        <div className='col-lg-3 col-6 mt-lg-0 mt-4'>
                            <button className={toggle === 2 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(2)}>
                                Paper Presentation
                            </button>
                        </div>
                        <div className='col-lg-3 col-6 mt-lg-0 mt-4'>
                            <button className={toggle === 3 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(3)}>
                                Table Clinic
                            </button>
                        </div>
                        <div className='col-lg-3 col-6 mt-lg-0  mt-4'>
                            <button className={toggle === 9 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(9)}>
                                Teeth Setting
                            </button>
                        </div>
                        <div className='col-lg-3 col-6  mt-4'>
                            <button className={toggle === 5 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(5)}>
                                Tooth Preparation
                            </button>
                        </div>
                        <div className='col-lg-3 col-6  mt-4'>
                            <button className={toggle === 6 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(6)}>
                                Video Presentation
                            </button>
                        </div>
                        <div className='col-lg-3 col-6  mt-4'>
                            <button className={toggle === 7 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(7)}>
                                Meme Presentation
                            </button>
                        </div>
                        <div className='col-lg-3 col-6  mt-4'>
                            <button className={toggle === 8 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(8)}>
                                Photography
                            </button>
                        </div>
                    </div>

                    <div className='tabcontents mt-lg-5'>

                        <div className={toggle === 8 ? "content active-content" : "content"}>
                            <h3 className='guidelines_head mt-lg-5 mt-4'>Guidelines for Photography  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    The <b>Registration</b> for dental photography should be done <b>before 11th March 2024 </b>
                                </li>
                                <li className='eventsList_parra'>
                                    The participants should get the photo approved by the concerned guide and HOD

                                </li>
                                <li className='eventsList_parra'>
                                    The participant and the guide of the presentation will take full responsibility for the content of the photo

                                </li>
                                <li className='eventsList_parra'>
                                    All submitted photos must have no borders, logos, copyright marks, identifying marks, or any other visible references or marks on the image

                                </li>
                                <li className='eventsList_parra'>
                                    Basic editing, including colour enhancement, filters, and cropping of the photos, is acceptable, provided any such editing does not affect the authenticity or genuineness of the photos.

                                </li>
                                <li className='eventsList_parra'>
                                    The photos submitted must be <b>at least 640 pixels</b>. Images should be <b>less than 15MB</b>. Photos must be in <b>JPEG format</b>.

                                </li>
                                <li className='eventsList_parra'>
                                    Advanced editing used to create illusions, deceptions, and/or manipulations, and the adding and removing of significant elements within the frame is prohibited

                                </li>
                                <li className='eventsList_parra'>
                                    Participants should send the photograph on or before <b>March 14th 2024 </b>

                                </li>
                                <li className='eventsList_parra'>
                                    The final photography should be sent to this email id: <a href='mailto:photo1stipsugcon@gmail.com'>photo1stipsugcon@gmail.com</a>

                                </li>
                                <li className='eventsList_parra'>
                                    <b>Top 30</b> photography will be announced on <b>March 20th 2024</b>. The <b>selected participants</b> will be announced in the website with Date, time and venue for the final presentation

                                </li>
                                <li className='eventsList_parra'>
                                    Selected participants should be present at the mentioned timing to take the image that would be informed by the scientific team in the stipulated time.

                                </li>
                                <li className='eventsList_parra'>
                                    The required armamentarium for taking photograph should be bought by the selected participants

                                </li>
                                <li className='eventsList_parra'>
                                    <b>Overall winner will be selected</b> and announced during valedictory function.

                                </li>
                                <li className='eventsList_parra'>
                                    All the sent photograph presentation will be displayed in the main auditorium.

                                </li>
                                <li className='eventsList_parra'>
                                    Participant name should not be there in the content, only their Convention registration number should be present in the bottom right corner

                                </li>
                            </ul>

                            <h3 className='guidelines_head mt-lg-5 mt-4'>Timeline  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Final Date for Registration: <b>11th March 2024</b>.
                                </li>
                                <li className='eventsList_parra'>
                                    The presenters are requested to upload their final meme presentation to <b>photo1stipsugcon@gmail.com</b> before <b>14th March 2024</b>.

                                </li>
                                <li className='eventsList_parra'>
                                    Request for extension of dates will not be entertained.

                                </li>
                                <li className='eventsList_parra'>
                                    Selected photogrpah announcement: <b>20th March 2024 </b>

                                </li>
                                <li className='eventsList_parra'>
                                    Best Overall winner will be announced during the valedictory function.

                                </li>
                            </ul>

                        </div>

                        <div className={toggle === 7 ? "content active-content" : "content"}>
                            <h3 className='guidelines_head mt-lg-5 mt-4'>Guidelines for Meme Presentation  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    The <b>Registration</b> for meme presentation should be done <b>before 11th March 2024</b>
                                </li>
                                <li className='eventsList_parra'>
                                    The participants should get the meme approved by the concerned guide and HOD

                                </li>
                                <li className='eventsList_parra'>
                                    The participant and the guide of the presentation will take full responsibility for the content of the meme

                                </li>
                                <li className='eventsList_parra'>
                                    There should not be any controversial contents in the meme.

                                </li>
                                <li className='eventsList_parra'>
                                    Create memes with simple, clear visuals and text that are easy to understand.

                                </li>
                                <li className='eventsList_parra'>
                                    The Meme presentation should be less than <b>20 MB and atleast 720 pixels in jpeg/tiff format</b> only

                                </li>
                                <li className='eventsList_parra'>
                                    The images and text in your memes are high-quality, clear, and easy to read, even when projected on a large screen.

                                </li>
                                <li className='eventsList_parra'>
                                    Participants should send the meme presentation on or before <b>March 14th 2024 </b>

                                </li>
                                <li className='eventsList_parra'>
                                    The final video presentation should be sent to this email id:<a href='mailto:meme1stipsugcon@gmail.com '>meme1stipsugcon@gmail.com </a>

                                </li>
                                <li className='eventsList_parra'>
                                    <b>Top 50</b> Meme presentation will be announced on <b>March 20th 2024</b>. The <b>selected participants</b> will be announced in the website with Date, time and venue for the final presentation

                                </li>
                                <li className='eventsList_parra'>
                                    Selected participants should be present at the mentioned timing and <b>overall winner will be selected </b>

                                </li>
                                <li className='eventsList_parra'>
                                    All the sent meme presentation will be played in the main auditorium.

                                </li>
                                <li className='eventsList_parra'>
                                    Participant name should not be there in the content, only their Convention registration number should be present in the bottom right corner

                                </li>



                            </ul>

                            <h3 className='guidelines_head mt-lg-5 mt-4'>Timeline  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Final Date for Registration: <b>11th March 2024</b>.
                                </li>
                                <li className='eventsList_parra'>
                                    The presenters are requested to upload their final meme presentation to <b>meme1stipsugcon@gmail.com</b> before <b>14th March 2024</b>.

                                </li>
                                <li className='eventsList_parra'>
                                    Request for extension of dates will not be entertained.

                                </li>
                                <li className='eventsList_parra'>
                                    Selected meme presentation announcement: <b>20th March 2024 </b>

                                </li>
                                <li className='eventsList_parra'>
                                    Best Overall winner will be announced during the valedictory function.

                                </li>

                            </ul>

                        </div>

                        <div className={toggle === 6 ? "content active-content" : "content"}>
                            <h3 className='guidelines_head mt-lg-5 mt-4'>Guidelines for Video Presentation  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    The <b>Registration</b> for video presentation should be done <b>before 11th March 2024 </b>
                                </li>
                                <li className='eventsList_parra'>
                                    The participants should get the video approved by the concerned guide and HOD


                                </li>
                                <li className='eventsList_parra'>

                                    The participant and the guide of the presentation will take full responsibility for the content of the video

                                </li>
                                <li className='eventsList_parra'>
                                    There should be no errors while playing the video


                                </li>
                                <li className='eventsList_parra'>
                                    There should not be any controversial contents in the video.


                                </li>
                                <li className='eventsList_parra'>
                                    Quality should be minimum <b>720 pixels with 16:9 aspect ratio </b>


                                </li>
                                <li className='eventsList_parra'>

                                    The video should be <b>in MP4 format only </b>

                                </li>
                                <li className='eventsList_parra'>
                                    The video presentation should be only up to <b>50 MB</b> and the time limit of the video is <b>3 minutes </b>


                                </li>
                                <li className='eventsList_parra'>
                                    Participants should send the video on or <b>before March 14th 2024</b>


                                </li>
                                <li className='eventsList_parra'>
                                    The final video presentation should be sent to this email id: <a href='mailto:video1stipsugcon@gmail.com'>video1stipsugcon@gmail.com</a>


                                </li>
                                <li className='eventsList_parra'>
                                    <b>Top 30</b> Video presentation will be announced on <b>March 20th 2024</b>. The <b>selected participants</b> will be announced in the website with Date, time and venue for the final presentation


                                </li>
                                <li className='eventsList_parra'>

                                    Selected participants should be present at the mentioned timing and <b>overall winner will be selected</b>

                                </li>
                                <li className='eventsList_parra'>
                                    All the sent video presentation will be played in the main auditorium.


                                </li>
                                <li className='eventsList_parra'>

                                    Participant name should not be there in the content, only their Convention registration number should be present in the bottom right corner

                                </li>


                            </ul>

                            <h3 className='guidelines_head mt-lg-5 mt-4'>Timeline  </h3>

                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Final Date for Registration: <b>11th March 2024</b>.
                                </li>
                                <li className='eventsList_parra'>
                                    The presenters are requested to upload their final video to <b>video1stipsugcon@gmail.com</b> before <b>14th March 2024</b>.

                                </li>
                                <li className='eventsList_parra'>
                                    Request for extension of dates will not be entertained.

                                </li>
                                <li className='eventsList_parra'>
                                    Selected video presentation announcement: <b>20th March 2024 </b>

                                </li>
                                <li className='eventsList_parra'>
                                    Best Overall winner will be announced during the valedictory function.

                                </li>
                            </ul>

                        </div>

                        <div className={toggle === 5 ? "content active-content" : "content"}>
                            <h3 className='guidelines_head mt-lg-5 mt-4'>Guidelines for Tooth Preparation  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Registration for tooth preparation should be done in website <b>before 11th March 2024 </b>

                                </li>
                                <li className='eventsList_parra'>
                                    Participants have to bring their own typodont model(preferably nissin)  with a complete set of unprepared teeth

                                </li>
                                <li className='eventsList_parra'>
                                    The required armamentarium for the tooth preparation, such as typhodont model, airotor handpiece, burs, should be brought by the participants

                                </li>
                                <li className='eventsList_parra'>
                                    The typodont model should be properly fitted with the screws intact

                                </li>
                                <li className='eventsList_parra'>
                                    The tooth that has to be prepared will be informed during the allotted time

                                </li>
                                <li className='eventsList_parra'>
                                    The <b>time duration</b> for tooth preparation will be <b>only 30 minutes</b>

                                </li>
                                <li className='eventsList_parra'>
                                    Participants should be <b>present before half an hour</b> at the venue to position the model in phantom head lab

                                </li>
                                <li className='eventsList_parra'>
                                    Kindly check the website <b>after 15th March the timing and venue</b> for the event

                                </li>


                            </ul>
                            <h3 className='guidelines_head mt-lg-5 mt-4'>Timeline  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Last Date for registration for tooth preparation:<b>11th March 2024 </b>


                                </li>
                                <li className='eventsList_parra'>
                                    Request for extension of dates will not be entertained.

                                </li>
                                <li className='eventsList_parra'>
                                    Best Tooth preparation of each session will be announced during the valedictory function.

                                </li>
                            </ul>

                        </div>

                        <div className={toggle === 9 ? "content active-content" : "content"}>
                            <h3 className='guidelines_head mt-lg-5 mt-4'>Guidelines for Teeth Setting  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Registration for teeth setting should be done in website<b> before 11th March 2024 </b>
                                </li>
                                <li className='eventsList_parra'>
                                    Only one participant is allowed for each teeth-setting

                                </li>
                                <li className='eventsList_parra'>
                                    Participants should bring their articulated casts, and the occlusal rims should be as per the M1 teeth set

                                </li>
                                <li className='eventsList_parra'>
                                    Participants should bring their necessary armamentarium for the teeth- setting, which includes a wax knife, wax carver, rubber bowl, rubber sheet and wax spatula

                                </li>
                                <li className='eventsList_parra'>
                                    Only <b>anterior teeth setting</b> with festooning and carving should be completed.

                                </li>
                                <li className='eventsList_parra'>
                                    <b>Time duration</b> for teeth setting will be only <b>1-hour </b>

                                </li>
                                <li className='eventsList_parra'>
                                    A teeth set will be provided for the exam

                                </li>
                                <li className='eventsList_parra'>
                                    Participants should be  <b>present before half an hour</b> at the venue

                                </li>
                                <li className='eventsList_parra'>
                                    Kindly check the website <b>after 15th March the timing and venue</b> for the event

                                </li>
                            </ul>

                            <h3 className='guidelines_head mt-lg-5 mt-4'>Timeline  </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Last Date for registration for teeth setting:<b>11th March 2024 </b>


                                </li>
                                <li className='eventsList_parra'>
                                    Request for extension of dates will not be entertained.

                                </li>
                                <li className='eventsList_parra'>
                                    Best Teeth setting of each session will be announced during the valedictory function.

                                </li>
                            </ul>

                        </div>



                        <div className={toggle === 2 ? "content active-content" : "content"}>
                            <h3 className='guidelines_head mt-lg-5 mt-4'> Guidelines for Paper Presentation </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Paper presentation should be in standard 16:9 dimension and submitted in <b>ppt/pptx format only </b>
                                </li>
                                <li className='eventsList_parra'>
                                    Only <b>2 authors</b> allowed for each paper presentation

                                </li>

                                <li className='eventsList_parra'>
                                    The final paper presentation has to be mailed in prior to<a href='mailto:paper1stipsugcon@gmail.com'>paper1stipsugcon@gmail.com </a> before 14th March 2024

                                </li>

                                <li className='eventsList_parra'>
                                    To prevent last minute hassle the participants are requested to submit the completed power point presentation  2 days before.

                                </li>

                                <li className='eventsList_parra'>
                                    Delegates will receive the details of Hall and Session timing in the website and are requested to share the same with their co-presenters for ease of conduct.

                                </li>

                                <li className='eventsList_parra'>
                                    All presentations will have to be presented during the convention in the allotted hall in the allotted time slot. The author and co-author must be present during their presentation.

                                </li>

                                <li className='eventsList_parra'>
                                    If the delegate fails to report during their allotted time, the author/co-author will not receive the presentation certificate and they will be eliminated from the competition.

                                </li>

                                <li className='eventsList_parra'>
                                    Each presenting team will get <b>8 minutes</b> for the presentation followed by <b>2 minutes of Q&A discussion</b>.

                                </li>

                                <li className='eventsList_parra'>
                                    Session Best Paper will be selected at the end of each session.

                                </li>

                                <li className='eventsList_parra'>
                                    Session Best Winner will be announced during valedictory function to be held on <b>23rd March 2024 </b>

                                </li>

                                <li className='eventsList_parra'>
                                    For any queries, you may contact in charge. Contact details of the hall in-charges will be updated in the website.</li>




                            </ul>
                            <h3 className='guidelines_head mt-4'> Timeline </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Date for submission of Abstract: <b>11th March 2024</b>.
                                </li>
                                <li className='eventsList_parra'>
                                    The presenters are requested to upload their paper to <b>paper1stipsugcon@gmail.com</b> before <b>14th March 2024</b>.

                                </li>
                                <li className='eventsList_parra'>
                                    Request for extension of dates will not be entertained.

                                </li>
                                <li className='eventsList_parra'>
                                    Best Paper of each session will be announced during the valedictory function.

                                </li>

                            </ul>
                        </div>

                        <div className={toggle === 1 ? "content active-content" : "content"}>
                            <h3 className='guidelines_head mt-lg-5 mt-4'> Guidelines for Poster Presentation </h3>
                            <ul className='mt-4'>

                                <li className='eventsList_parra'>
                                    E-Poster should be done in landscape format and submitted in <b>jpeg/tiff/pdf format only </b>
                                </li>

                                <li className='eventsList_parra'>
                                    Only <b>2 authors</b> allowed for each Poster presentation

                                </li>

                                <li className='eventsList_parra'>
                                    The final E-Poster has to be mailed in prior to <a href='mailto:poster1stipsugcon@gmail.com'>poster1stipsugcon@gmail.com</a> before 14th March 2024

                                </li>

                                <li className='eventsList_parra'>
                                    To prevent last minute hassle the participants are requested to submit the completed E-poster 2 days before.

                                </li>

                                <li className='eventsList_parra'>
                                    Delegates will receive the details of Hall and Session timing in the website and are requested to share the same with their co-presenters for ease of conduct.

                                </li>

                                <li className='eventsList_parra'>
                                    Student delegates (Both author and co-author) are expected to be present during the presentation.

                                </li>

                                <li className='eventsList_parra'>
                                    If the delegate fails to report during their allotted time, the author/co-author will not receive the presentation certificate and they will be eliminated from the competition.

                                </li>

                                <li className='eventsList_parra'>
                                    Your E-poster will be displayed on the screen according to the schedule and the Participants are requested to present for <b>3 minutes followed by 2 minute Q & A session</b>. Any increase in presentation timing will lead to disqualification.

                                </li>

                                <li className='eventsList_parra'>
                                    Session Best Poster will be selected at end of each session.

                                </li>

                                <li className='eventsList_parra'>
                                    Session Best Winners will be announced during valedictory function to be held on 23rd March 2024

                                </li>

                                <li className='eventsList_parra'>
                                    For any queries, you may contact Hall in charge. Contact details of the hall in-charges will be updated in the website.

                                </li>
                            </ul>
                            <h3 className='guidelines_head mt-lg-5 mt-4'> Timeline </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Date for submission of Abstract (Student):<b> 11th March 2024 </b>



                                </li>
                                <li className='eventsList_parra'>
                                    The presenters (Student) are requested to upload their E-poster to <a href='mailto:poster1stipsugcon@gmail.com'></a> by: <b>14th March 2024 </b>

                                </li>
                                <li className='eventsList_parra'>
                                    Request for extension of dates will not be entertained

                                </li>
                                <li className='eventsList_parra'>
                                    Best Poster of each session will be announced during the valedictory function.

                                </li>

                            </ul>

                        </div>

                        <div className={toggle === 3 ? "content active-content" : "content"}>
                            <h3 className='guidelines_head mt-lg-5 mt-4'> Guidelines for Table Clinic </h3>

                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Delegates should check the details of Hall and Session timing in the website and are requested to share the same with their co-presenters for ease of conduct.

                                </li>

                                <li className='eventsList_parra'>
                                    Each presenting team will have to display their table clinic in the allotted location and will be allowed to explain their project to the judging panel for <b>3 minutes </b>following which Q&A discussion will take place.

                                </li>

                                <li className='eventsList_parra'>
                                    <b>All materials</b> required for the table clinic has to be brought <b>by the student participants</b>. No assistance will be provided.

                                </li>

                                <li className='eventsList_parra'>
                                    <b>Should report to the mentioned Hall 30 minutes before and arrange before the scheduled timing at their respective places allotted by the organising team </b>

                                </li>

                                <li className='eventsList_parra'>
                                    The Presenter and co-presenters must be present during their presentation.

                                </li>

                                <li className='eventsList_parra'>
                                    If the delegate fails to report during their allotted time, the author/co-author will not receive the presentation certificate and they will be eliminated from the competition.

                                </li>

                                <li className='eventsList_parra'>
                                    The session winners will be selected at the end of each session.

                                </li>

                                <li className='eventsList_parra'>
                                    Session Best Winner will be announced during valedictory function to be held on <b>23rd March 2024 </b>

                                </li>

                                <li className='eventsList_parra'>
                                    For any queries, you may contact in charge. Contact details of the hall in-charges will be ​updated in the website.

                                </li>

                            </ul>
                            <h3 className='guidelines_head mt-4'> Timeline </h3>
                            <ul className='mt-4'>
                                <li className='eventsList_parra'>
                                    Last Date for registration for table clinic:<b>11th March 2024 </b>


                                </li>
                                <li className='eventsList_parra'>
                                    Request for extension of dates will not be entertained.
                                </li>
                                <li className='eventsList_parra'>
                                    Best Table clinic of each session will be announced during the valedictory function.
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div className='row'>
                <div className='col-lg-12 col-12'>


                    <div className='d-flex justify-content-start align-items-center mt-lg-5 mt-4'>
                        <div>
                            <input type='checkbox' className='mb-0' value={checked} onChange={handleChange} />
                        </div>
                        <div className='mx-3'>
                            <p className='term_parra mb-1'>
                                I accept all the terms and conditions
                            </p>
                        </div>
                    </div>
                    {posterDetails.isSuccess === true ?
                        (<Link to={'/eventdetails'} className='link_all'>
                            <button className='button_events mt-4' disabled={checked ? false : true}>
                                EventsDetails
                            </button>
                        </Link>) : (
                            <Link to={'/eventform'} className='link_all'>
                                <button className='button_events mt-4' disabled={checked ? false : true}>
                                    Register
                                </button>
                            </Link>
                        )
                    }

                    {/* <Link to={'/eventform'} className='link_all'>
                 <button className='button_events mt-4' disabled={checked?false :true}>
                     Register
                 </button>
                 </Link> */}


                </div>
            </div>
        </div>
    )
}

export default EventsSplit