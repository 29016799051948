import React from 'react';
import './about.css';
import ips1 from '../../assests/Aboutus/1.webp';
import ips2 from '../../assests/Aboutus/2.webp';
import ips3 from '../../assests/Aboutus/3.webp';
import ips4 from '../../assests/Aboutus/4.webp';
import ips5 from '../../assests/Aboutus/5.webp';
import ips6 from '../../assests/Aboutus/6.webp';
import ips7 from '../../assests/Aboutus/7.webp';
import ips8 from '../../assests/Aboutus/8.webp';
import ips9 from '../../assests/Aboutus/9.webp';
import ips10 from '../../assests/Aboutus/10.webp';
import ips11 from '../../assests/Aboutus/11.webp';
import ips21 from '../../assests/Aboutus/21.webp';
import ips22 from '../../assests/Aboutus/22.webp';
import ips23 from '../../assests/Aboutus/23.webp';
import ips24 from '../../assests/Aboutus/24.webp';
import ips25 from '../../assests/Aboutus/25.webp';
import ips26 from '../../assests/Aboutus/26.webp';


const Aboutips = () => {
  return (
    <div className='container-fluid col-10'>
     <div className='row ips_main d-flex justify-content-center align-items-center'>
        <div className='col-lg-12 col-12 mt-lg-0 mt-5'>
         <h2 className='ipshead text-center'>IPS Office Bearers</h2>
        </div>
     </div>

     <div className='row mt-5'>
     <div className='col-lg-4 col-12'>
            <img src={ips3} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips4} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips5} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450'  loading='lazy'/>
        </div>
        
     </div>
     <div className='row mt-5'>
     <div className='col-lg-4 col-12'>
            <img src={ips6} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips7} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips8} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
       
     </div>
     <div className='row mt-5'>
     <div className='col-lg-4 col-12 '>
            <img src={ips9} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips10} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips11} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
     </div>

     <div className='col-lg-12 col-12 mt-5'>
         <h2 className='ipshead text-center pt-5'>TN IPS Office Bearers</h2>
        </div>
        <div className='row mt-5'>
        <div className='col-lg-6 col-12'>
            <img src={ips2} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-6 col-12 mt-lg-0 mt-5'>
            <img src={ips1} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        
     </div>
        <div className='row mt-5'>
        <div className='col-lg-4 col-12'>
            <img src={ips21} className='img-fluid ipsImages center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={ips25} className='img-fluid ipsImages  center-block d-block mx-auto mt-lg-0 mt-5' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={ips22} className='img-fluid ipsImages  center-block d-block mx-auto mt-lg-0 mt-5' alt='' width='500' height='450' loading='lazy' />
        </div>
     </div>
     <div className='row mt-5'>
        <div className='col-lg-4 col-12'>
            <img src={ips26} className='img-fluid ipsImages  center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={ips23} className='img-fluid ipsImages  center-block d-block mx-auto mt-lg-0 mt-5' alt='' width='500' height='450' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12'>
            <img src={ips24} className='img-fluid ipsImages  center-block d-block mx-auto mt-lg-0 mt-5' alt='' width='500' height='450' loading='lazy' />
        </div>
     </div>
    </div>
  )
}

export default Aboutips