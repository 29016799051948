import React from 'react';
import './about.css';
import About from '../../assests/Aboutus/image.webp'

const FirstSection = () => {
  return (
    <>
    <div className='aboutmain'>
      <h2 className='about_head mt-lg-0 mt-4'>
        About us
      </h2>
      <p className='about_para mt-lg-4 mt-3'>
      The Theme being ‘Digital Millennium UG Graduates’, the convention is going to offer scientific extravaganza with newer clinical approaches and evidences from experts in the field.
      </p>
    </div>

    <div className='container-fluid col-10 mt-lg-5'>
        <div className='row pt-5 d-flex justify-content-center align-items-center flex-lg-row flex-column-reverse'>
            <div className='col-lg-6 col-12'>
                <img src={About} className='img-fluid aboutHead_image center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy' />
            </div> 
            <div className='col-lg-6 col-12'>
             <h2 className='aboutHeadHost '>
              
              Host
             </h2>
             <h2 className='aboutHead'>
              
              Sri Ramachandra Dental College and Hospital
             </h2>
             <p className='aboutParra mt-4'>
             Sri Ramachandra Institute of Higher Education and Research (Deemed to be University) has been ranked among the top health sciences universities in India. The institute is dedicated to serve the society as a centre of excellence with emphasis on medical education, research and health care. Sri Ramachandra Dental College and Hospital was started in 1995 as a constituent college of Sri Ramachandra Institute of Higher Education and Research.  Utilizing a floor space of 2,29,000 sq.ft, the college has 330 well equipped dental chairs to fulfill the needs of the Undergraduates and Postgraduates in every branch of dentistry. There are specially designed laboratories and teaching facilities such as lecture halls, auditoria and departmental seminar rooms and departmental libraries which make this a well-recognized institution in the country. The dental college holds Memorandum of agreement with various universities across the world such as University of Malaya, West Virginia University, Fusan National University, University of Southampton and Ajman University. The institute holds the 7th rank in National Institutional Rank Framework (nirf) and has also been accredited as “A” Grade institute by NAAC. The University is spread over 150 acres and is housed in a refreshingly green campus with well-manicured lawns, sprawling open spaces and trees. The constructed space of the institute spreads over two million square feet and houses technologically advanced, fully air-conditioned state of the art class rooms, modern laboratories and one of the largest medical libraries.

             </p>
            </div>
        </div>
    </div>
    </>
  )
}

export default FirstSection