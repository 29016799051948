import React from 'react';
import './Register.css'
import RegisterContent from '../../components/register/RegisterContent';
import RegisterForm from '../../components/register/RegisterForm';
import Header from '../../components/header/Header';

const Register = () => {
  return (
    <div>
      <Header />
       <RegisterContent />
       <RegisterForm />
    </div>
  )
}

export default Register