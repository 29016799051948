import React from 'react'
import './Footer.css';
import Logo from '../../assests/home/logo 1.webp';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer id='contact'>
      <div className='container-fluid col-10'>
        <div className='row borderfooter_parra p-4'>
          <div className='col-lg-4 col-12'>
            <img src={Logo} className='img-fluid logo_footer center-block d-block mx-auto mx-lg-0' alt='footerLogo' width='100' height='100' loading='lazy' />
            {/* <p className='logos_para mt-4'>
            We the Tamil Nadu & Pondicherry branch
             of Digital Millennium UG Graduates Society in association with Ramachandra Dental College take immense
            </p> */}
          </div>
          <div className='col-lg-2 col-6 mt-lg-0 mt-5'>
            <h3 className='quick_head'>
              Quick Links
            </h3>
            <p className='footer_links mt-4'>
            <Link className='footer_link' to={'/'}>
              Home
            </Link>
            </p>
            
            <p className='footer_links'>
            <Link className='footer_link' to={'/about'}>
              About Us
            </Link>
            </p>
          

          <p className='footer_links'>
          <Link className='footer_link' to={'/events'}>
              Events
            </Link>
          </p>
            
            <p className='footer_links'>
            <Link className='footer_link' to={'/register'}>
              Registration
            </Link>
            </p>
          
       
          </div>
          <div className='col-lg-2 col-6 mt-lg-0 mt-5'>
            <h3 className='quick_policy'>
              Policy
            </h3>
            <p className='footer_links mt-4'>
            <Link className='footer_link' to={'/termpolicy'}>
              Privacy Policy
            </Link>
            </p>
            
            <p className='footer_links'>
            <Link className='footer_link' to={'/termpolicy'}>
             Refund Policy
            </Link>
            </p>
          

          <p className='footer_links'>
          <Link className='footer_link' to={'/termpolicy'}>
          Term of Services
            </Link>
          </p>
          <p className='footer_links'>
          <a className='footer_link' href='./Accommodation for students UG National convention.pdf'>
          Accomodation
            </a>
          </p>
          </div>

          <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
          <h3 className='quick_head'>
             Contact Us
            </h3>
            <p className='footer_links mt-4'>
              <a className='footer_link mx-2' href='tel:+919962403796'>Dr Kapil Raju :-  +91 99624 03796</a>
            </p>

            <h3 className='quick_address mt-5'>
         Email
            </h3>
            <p className='footer_link mt-4'>
             <a href='mailto:1stugipscon@gmail.com' className='link_all'>
             1stugipscon@gmail.com
             </a>
            </p>
          </div>
        </div>


<div className='end_footer'>
  <div className='col-12'>
    <p className='end_parra mt-3 mb-0'>
    All Rights Reserved © 2024 1st IPS UG Convention.
    </p>
  </div>
</div>

      </div>
    </footer>
  )
}

export default Footer