import React from 'react'
import FirstSection from '../../components/About/FirstSection'
import Aboutips from '../../components/About/Aboutips'
import Header from '../../components/header/Header'

const About = () => {
  return (
    <div>
        <Header />
      <FirstSection />
      <Aboutips />
    </div>
  )
}

export default About