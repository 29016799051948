import React from 'react'
import Header from '../components/header/Header'

const TermofSerivices = () => {
  return (
    <>
     <Header />
      <div className='eventformmain'>
    <h3 className='eventformhead'>
       Terms & Policy
    </h3>
    </div>
<div className='container-fluid col-10'>
              
<h3 className='guidelines_head mt-lg-5 mt-4'>Terms and Conditions </h3>

<p className='eventsList_parra mt-3'>
1st Indian Prosthodontic Society National UG Convention has a limited Cancellation & Refund policy. In case of cancellation at any stage, bank transfer fee will not be refunded. It is applicable to bank transfer payments only. The refund will be done within one month after the Convention to the same account from which transaction was made. <br />
Due to unexpected reasons, the venue (or) date for the program could be changed from the place (or) date already decided. The Convention organising committee hereby confirms its right to change the venues, dates and its speakers when necessary. All changes will be updated in the website. It's participants responsibility to visit our website for any updates. 
It is mandatory to email all the cancellations well prior to the deadline mentioned below to Dr. Maheshwaran, Chennai <a href='mailto:1stugipscon@gmail.com'>1stugipscon@gmail.com</a> 
</p>


 

<h3 className='guidelines_head mt-lg-5 mt-4'>Cancellation policy </h3>

<p className='eventsList_parra mt-3'>
All private data including Payment Details and Credit Card information will be transmitted securely using 256 bit SSL technology or similar.
 This is the same level of security available through your web browser when connecting to your Internet Bank provider.
    </p>

<ol className='mt-4'>
<li className='eventsList_parra'>
Registrations are non-transferrable 
</li>
<li className='eventsList_parra'>
Registration Refund / cancellation policy 
 
 <ol style={{listStyleType:"upper-roman"}}>
    <li className='eventsList_parra'>
    No refunds will be done on cancellation of registration after 28th February 2024.. 
    </li>
 </ol>
</li>

<li className='eventsList_parra'>
Refunds in the case of procedural lapses would mean

<ol style={{listStyleType:"upper-roman"}}>
    <li>
    Money erroneously paid 
    </li>
    <li>
    Eligibility criteria not being met 
    </li>
    <li>
    Required documents not submitted 
    </li>
</ol>
</li>

<li className='eventsList_parra'>
All the refunds will be processed only after one month of scheduled completion of Convention. 
</li>

<li className='eventsList_parra'>
All the requests for the cancellation will be processed by the organising committee and any decision in this regard will be final and binding.In case of cancellation at any stage, bank transfer fee will not be refunded. It is applicable to bank transfer payments only. 
</li>

<li className='eventsList_parra'>
Cancellations are to be requested in writing only to the Convention secretariat - <a href='mailto:1stugipscon@gmail.com '>1stugipscon@gmail.com </a>
</li>
<li className='eventsList_parra'>
Telephonic cancellations will not be entertained. 
</li>
<li className='eventsList_parra'>
Only the base registration amount will be refunded and not the GST or Service charges and Bank Charges 
</li>
<li className='eventsList_parra'>
In case of force majeure or Act of GOD, or natural calamities or pandemics the Convention COC with have the authority to convert physical registrations into digital platforms and the difference as appropriate will be refunded. 
</li>

<li className='eventsList_parra'>
All legal jurisdictions will be that is binding for the Chennai, Tamil Nadu region. 
</li>
</ol>


 

<h3 className='guidelines_head mt-lg-5 mt-4'>
Convention Policy 
    </h3>

<p className='eventsList_parra mt-3'>

The Convention organising committee and the service provider of the website uses and protects any information that you give us when you use the Convention website. 

We are committed to ensuring that your privacy is protected though all precautions are taken the COC do not take any liability for accidental hacking or breach of the website my malicious parties. 
   </p>

   <h3 className='guidelines_head mt-lg-5 mt-4'>
   Disclosure 

</h3>

<p className='eventsList_parra mt-3'>
The 1st Indian prosthodontics society UG Convention is planned and presented by the Indian prosthodontics Society and the UG Convention organising Committee to provide attendees with a relevant and rewarding continuing education experience. However, neither the content of a course not the use of specific products in any program should be construed as indication endorsement or approval of the views presented or the products used by the IPS or the COC, any of the sub committees. Speakers are required to disclose to participants any financial, commercial or promotional interest in a product or company that may influence their presentations; however, the IPS or the COC shall not be liable for the speaker's failure to disclose such interest. Please be advised that courses, speakers or scheduling may change without notice. 
</p>
</div>
    </>
  )
}

export default TermofSerivices