import React from 'react';
import './IPSBar.css';
import {Link} from 'react-router-dom'
import ips1 from '../../assests/home/1.webp';
import ips2 from '../../assests/home/2.webp';
import ips3 from '../../assests/home/3.webp';
import ips4 from '../../assests/home/4.webp';
import ips5 from '../../assests/home/5.webp';
import ips6 from '../../assests/home/6.webp';

const IPSBarrer = () => {
  return (
    <div className='container-fluid col-10'>
     <div className='row ips_main d-flex justify-content-center align-items-center'>
        <div className='col-lg-4 col-0'>
            
        </div>
        <div className='col-lg-4 col-12'>
         <h2 className='ipshead mt-lg-0 mt-5'>IPS Office Bearers</h2>
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-4'>
            <Link to={'/about'} className='link_all'>
            <button to={'/about'} className='viewhome_button right-block d-block ms-lg-auto'>
            View All
           </button>
            </Link>
        </div>
     </div>
     <div className='row mt-lg-5'>
     <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips3} className='img-fluid ipsImages center-block d-block mx-auto '  width='500' height='450' alt='' loading='lazy' />
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips4} className='img-fluid ipsImages center-block d-block mx-auto' width='500' height='450'  alt='' loading='lazy'/>
        </div>
        <div className='col-lg-4 col-12 mt-lg-0 mt-5'>
            <img src={ips5} className='img-fluid ipsImages center-block d-block mx-auto' width='500' height='450' alt='' loading='lazy' />
        </div>

     </div>
    </div>
  )
}

export default IPSBarrer