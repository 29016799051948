import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Component.css'
import AdminHeader from './components/header/AdminHeader';

const Component = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [registerDetails, setRegisterDetails] = useState([]);
  const [search, setSearch] = useState('');
  const [search1, setSearch1] = useState('');
  const [searchable, setSearchable] = useState('');

  const [toggle, setToggle] = useState(1);

  const handleToggle = (index) => {
    setToggle(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://back-ram.onrender.com/api/auth/getDetails?presentation=${search}`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://back-ram.onrender.com/api/auth/getDetails?cocircularActivities=${search1}`);
        setData1(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [search1]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`https://back-ram.onrender.com/api/auth/getAllUser?username=${searchable}`);
        setRegisterDetails(res.data)
      }
      catch (error) {

      }
    }

    fetchData();
  }, [searchable])

  // if (!Array.isArray(data) || data.length === 0) {
  //   return <p>No data available</p>;
  // }


  return (
    <>
      <AdminHeader />

      <div className='adminDetails'>

        <div className='container-fluid col-11 mt-5'>
          <div className='row'>
            <div className='col-12'>
              <div className=' background_detailsadmin  d-flex justify-content-between align-items-center'>
                <div>
                  <h3 className='guidelines_headadmin'>Admin Details</h3>
                </div>
                <div>

                  <button className={toggle === 1 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(1)}>
                    Scientific Registration
                  </button>

                </div>
                <div>

                  <button className={toggle === 2 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(2)}>
                    Co-Curricular Registration
                  </button>

                </div>
                <div>

                  <button className={toggle === 3 ? "tabs active-tabs" : "tabs"} onClick={() => handleToggle(3)}>
                    Registration Details
                  </button>

                </div>

                <div>
                  {toggle === 3 && <input type='search' className='adminSearch' placeholder='Search UserName' onChange={(e) => setSearchable(e.target.value)} />}
                  {toggle === 2 && <input type='search' className='adminSearch' placeholder='Search CoCircular Category' onChange={(e) => setSearch1(e.target.value)} />}
                  {toggle === 1 && <input type='search' className='adminSearch' placeholder='Search Scientific Category' onChange={(e) => setSearch(e.target.value)} />}

                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid col-12 mt-5'>
        <div className='row'>
          <div className='col-12'>
            <table class="" className={toggle === 1 ? "active-content table table-striped table-bordered" : "content table table-striped"}>
              <thead>
                <tr>
                  <th scope="col">Role</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">College</th>
                  <th scope="col">Year</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone no</th>
                  <th scope="col">Scientific</th>
                  <th scope="col">Main Category</th>
                  <th scope="col">Sub Category</th>
                  <th scope="col">Title</th>

                </tr>
              </thead>
              <tbody>
                {data.map((postGroup, index) => (
                  <React.Fragment key={index}>
                    {postGroup.map(({ post, authors, coAuthors }) => (
                      <React.Fragment key={post._id}>
                        {authors.map((author, authorIndex) => (
                          <tr key={`mainAuthor-${authorIndex}`}>
                            {post.presentation !== "" && (
                              <>
                                <td>Main Author</td>
                                <td>{author.authorId}</td>
                                <td>{author.username}</td>
                                <td>{author.collegename}</td>
                                <td>{author.year}</td>
                                <td>{author.email}</td>
                                <td>{author.PhoneNumber}</td>
                                <td>{post.presentation}</td>
                                <td>{post.mainCategory}</td>
                                <td>{post.subCategory}</td>
                                <td>{post.title}</td>
                              </>
                            )}

                          </tr>
                        ))}
                        {coAuthors.map((coAuthor, coIndex) => (
                          <tr key={`coAuthor-${coIndex}`}>
                            {post.presentation !== "" && (
                              <>
                                <td>Co-Author</td>
                                <td>{coAuthor.authorId}</td>
                                <td>{coAuthor.username}</td>
                                <td>{coAuthor.collegename}</td>
                                <td>{coAuthor.year}</td>
                                <td>{coAuthor.email}</td>
                                <td>{coAuthor.PhoneNumber}</td>
                                <td>{post.presentation}</td>
                                <td>{post.mainCategory}</td>
                                <td>{post.subCategory}</td>
                                <td>{post.title}</td>
                              </>
                            )}


                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <table class="" className={toggle === 2 ? "active-content table table-striped table-bordered" : "content table table-striped"}>
              <thead>
                <tr>
                  <th scope="col">Role</th>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">College</th>
                  <th scope="col">Year</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone no</th>
                  <th scope="col">Cocurricular</th>

                </tr>
              </thead>
              <tbody>
                {data1.map((postGroup, index) => (
                  <React.Fragment key={index}>
                    {postGroup.map(({ post, authors, coCircularAuthor }) => (
                      <React.Fragment key={post._id}>
                        {authors.map((author, authorIndex) => (
                          <tr key={`mainAuthor-${authorIndex}`}>
                            {post.cocircularActivities !== "" && (
                              <>
                                <td>Main Author</td>
                                <td>{author.authorId}</td>
                                <td>{author.username}</td>
                                <td>{author.collegename}</td>
                                <td>{author.year}</td>
                                <td>{author.email}</td>
                                <td>{author.PhoneNumber}</td>
                                <td>{post.cocircularActivities}</td>
                              </>
                            )}



                          </tr>
                        ))}
                        {coCircularAuthor.map((coAuthor, coIndex) => (
                          <tr key={`coAuthor-${coIndex}`}>
                            {post.cocircularActivities !== "" && (
                              <>
                                <td>Co-Curricular Author</td>
                                <td>{coAuthor.authorId}</td>
                                <td>{coAuthor.username}</td>
                                <td>{coAuthor.collegename}</td>
                                <td>{coAuthor.year}</td>
                                <td>{coAuthor.email}</td>
                                <td>{coAuthor.PhoneNumber}</td>
                                <td>{post.cocircularActivities}</td>
                              </>
                            )}

                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <table class="" className={toggle === 3 ? "active-content table table-striped table-bordered" : "content table table-striped"}>
              <thead>
                <tr>

                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope='col'>College Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone no</th>
                  <th scope="col">Food</th>
                </tr>
              </thead>
              <tbody>
                {registerDetails.map((details) => {
                  return (
                    <tr key={details._id}>
                      <td>{details.authorId}</td>
                      <td>{details.username}</td>
                      <td>{details.collegename}</td>
                      <td>{details.email}</td>
                      <td>{details.PhoneNumber}</td>
                      <td>{details.food}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  );
};

export default Component;
