import React from "react";
import Slider from "react-slick";
import './Simple.css'

export default function SimpleSlider() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay:true,
    autoplaySpeed:2000,
    slidesToShow: 1,
    focusOnSelect: false,
  pauseOnHover:false,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings}>
      <div className="background_images1">
      </div>
      <div className="background_images2">

      </div>
      <div className="background_images3">
     
      </div>
      <div className="background_images4">
     
      </div>
    </Slider>
  );
}