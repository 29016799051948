import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assests/Aboutus/logo 2.webp'
import './Header.css';
import { AuthContext } from '../../context/AuthContext';
import { AdminContext } from '../../context/AdminContext';

const Header = () => {

  const { user, dispatch } = useContext(AuthContext);
  const { adminUser } = useContext(AdminContext);

  const [addnavbar, setAddnavbar] = useState(false)
  const [addnavlink, setAddnavlink] = useState(false);
  const [activeLink, setActiveLink] = useState('/');

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };
  useEffect(() => {
    window.addEventListener('scroll', function (e) {

      if (window.scrollY >= 100) {
        setAddnavbar(true)
        setAddnavlink(true)
      }
      else {
        setAddnavbar(false)
        setAddnavlink(false);
      }
    })
  }, [window.scrollY]);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  return (
    <>

      <nav className={addnavbar ? "setbackNav navbar navbar-expand-lg" : "navbar navbar-expand-lg"}>
        <div className="container-fluid col-10">
          <Link className="navbar-brand" to={"/"}><img src={Logo} className='img-fluid logo' alt='logo' width='50' height='50' loading='lazy' /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex justify-content-center align-items-center">
              <li className="nav-item mx-2">
                <Link className={(addnavlink ? " setLink nav-link link_all" : "nav-link link_all")}
                  onClick={() => handleLinkClick('/')}
                  aria-current="page" to={"/"}>Home</Link>
              </li>
              <li className="nav-item mx-2">
                <Link className={(addnavlink ? "setLink nav-link link_all" : "nav-link link_all")}
                  onClick={() => handleLinkClick('/about')} to={'/about'}>About Us</Link>
              </li>
              <li className="nav-item mx-2">
                <Link className={(addnavlink ? "setLink nav-link link_all" : "nav-link link_all")}
                  onClick={() => handleLinkClick('/events')} to={"/events"}>Events</Link>
              </li>
              {/* <li className="nav-item mx-2">
                <Link className={addnavlink ? "setLink nav-link link_all" : "nav-link link_all"} to={"/register"}>Register</Link>
              </li> */}
              {/* <li className="nav-item mx-2">
              <Link className={addnavlink ? "setLink nav-link link_all" : "nav-link link_all"} to={"/#contact"}>Contact Us</Link>
            </li> */}



              <li className="nav-item mx-2">
                {user ? (
                  <Link onClick={handleLogout} className={addnavlink ? "setLink nav-link link_all" : "nav-link link_all "} to={"/login"}>
                    <button className="navbarlogin_button">Logout</button>
                  </Link>
                ) : (
                  <Link className={addnavlink ? "setLink nav-link link_all" : "nav-link link_all "} to={"/login"}>
                    <button className="navbarlogin_button">Login</button>
                  </Link>
                )}

              </li>

            </ul>
          </div>
        </div>
      </nav>


      <div className={`mobile_container `}>
        <div className={`header_mobile ${menuOpen ? 'menu-open' : ''}`}>
          <div className="container-fluid col-10">
            <div className="d-flex justify-content-between align-items-center mobile_pallete">
              <div>
                <Link to={'/'}>
                  <img src={Logo} className='img-fluid logo' alt='logo' width='50' height='50' loading='lazy' />
                </Link>
              </div>
              <div className="icon-container mr-5" onClick={toggleMenu}>
                <i class="bi bi-list hamburger"></i>
              </div>
            </div>
          </div>
          <div className="mobile-menu">
            <ul className="menu">
              <li className="menu-item" onClick={toggleMenu}>
                <Link to={'/'}>Home</Link>
              </li>
              <li className="menu-item" onClick={toggleMenu}>
                <Link to={'/about'}>About Us</Link>
              </li>
              <li className="menu-item" onClick={toggleMenu}>
                <Link to={'/events'}>Events</Link>
              </li>
              {/* <li className="menu-item" onClick={toggleMenu}>
                <Link to={'/register'}>Register</Link>
              </li> */}
              <li className="menu-item" onClick={toggleMenu}>
                <Link to={'/login'}>Login</Link>
              </li>
              <li className="menu-item">
                <div className="icon-container1 mt-4" onClick={toggleMenu}>
                  <i class="bi bi-x-circle closed"></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header