import React from 'react'
import about  from '../../assests/home/about.webp'
import './AboutHome.css'
import video from '../../assests/home/video.mp4'

const About = () => {
  return (
    <div className='about_main'>
      <div className='container-fluid col-10'>
      <div className='row  d-flex justify-content-center align-items-center'>
            <div className='col-lg-6 col-12'>
            <video className='abouthome_images' autoPlay loop muted >
      <source src={video} type="video/mp4"  width='400' height='400'  alt='video'/>
     </video>
            </div>
            <div className='col-lg-6 col-12'>
              <h2 className='abouthome_heading mt-lg-0 mt-4'>
                1st UG Prosthodontic Convention
              </h2>
           <p className='homeabout_para mt-2'>
           <span className='abouthome_heading'>“Digital Millennium”</span>
<br/>
Sri Ramachandra Dental College & Hospital, SRIHER (DU) in association with Indian Prosthodontic Society Tamil Nadu and Puducherry branch proudly organizes the first Undergraduate National convention under the aegis of Indian Prosthodontic Society. ‎
With the world going digital, this first undergraduate convention based on theme "Digital Millennium" will enable the students to understand and learn the various aspects of digital prosthodontics. The students will be able to blend the Conventional treatment and digitalisation to render an ethical and effective Prosthodontic treatment to their patients. This first national undergraduate convention will not only be academically enriching but will also be a perfect weekend getaway for the young doctors to travel and explore the beauty of Chennai.
The registration includes entry and participation in scientific sessions, co-curricular events, and trade. Veg and Non-veg lunch will be provided with high tea for 2 days. Participation certificate for the convention and scientific events will be given separately. Best presentation awards for individual sessions for the winning participants and Overall winner trophy for the college will also be given

           </p>
            </div>
        </div>
      </div>
        
    </div>
  )
}

export default About