import React from 'react'
import Images from '../../assests/home/organized.webp';
import Images1 from '../../assests/home/organized1.webp';
import Images2 from '../../assests/home/organized2.webp';
import './Organized.css';

const Organized = () => {
  return (
    <div className='container-fluid col-10'>
      <div className='row'>
        <div className='col-lg-6 col-12 mt-lg-0 mt-5'>
          <h2 className='text-center abouthome_heading'>Organiser</h2>
            <img src={Images} className='img-fluid organized_images center-block d-block mx-auto' alt='' width='500' height='450' loading='lazy'/>
        </div>
        <div className='col-lg-6 col-12 mt-lg-0 mt-5'>
        <h2 className='text-center abouthome_heading'>Host</h2>
            <img src={Images1} className='img-fluid organized_images center-block d-block mx-auto mx-lg-0' width='500' height='450' alt='' loading='lazy'/>
        </div>
        <div className='col-lg-12 col-12 mt-5'>
        <h2 className='text-center abouthome_heading'>Under the Aegis of</h2>
            <img src={Images2} className='img-fluid organized_images center-block d-block mx-auto mt-3' width='500' height='450' alt='' loading='lazy'/>
        </div>
      </div>
    </div>
  )
}

export default Organized