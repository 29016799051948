import React, { useContext } from 'react';
import Ones from '../../assests/Events/one.webp';
import './EventsBanner.css';
import Profile from '../../assests/Events/profile.webp'
import { AuthContext } from '../../context/AuthContext';

const Eventsbanner = () => {
  const {user}=useContext(AuthContext)
  const PF = "https://back-ram.onrender.com/images/";
  return (
    <div className='eventsbanner'>
       <div className='container-fluid col-10 mt-5'>
        <div className='row banner_eventsRelati flex-lg-row flex-column-reverse'>
          {user ? (
  <div className='col-lg-6 col-12'>
  <div className='users_details mt-lg-0 mt-5 d-flex justify-content-center align-items-center flex-column'>
    {user && user.details.photos ? <img src={`https://jokarcreationswebstorage.blob.core.windows.net/wdc2023photo/${user.details.photos}`} className='img-fluid profileImages' alt='' width='500' height='450' loading='lazy' /> : ""}  
      <h3 className='profile_head mt-3'>{user.details.username}</h3>
      <p className='profileyear_para'>
     {user.details.authorId}
      </p>
      <p className='profileyear_para'>
     {user.details.collegename}
      </p>
      <p className='profileEmail'>
      {user.details.email}
      </p>
      <p className='profileEmail'>
     {user.details.PhoneNumber}
      </p>
      <p className='loginListParra'>
                        Kindly click on the below link to join WhatsApp group<br/>
                        <a href='https://chat.whatsapp.com/GRSSFPxyMk83je1jyftPzZ' target="_blank">https://chat.whatsapp.com/GRSSFPxyMk83je1jyftPzZ</a>
                        </p>
  </div>
</div>
          ): ""}
          
            <div className='col-lg-6 col-12'>
              <div className='d-flex justify-content-center align-items-center'>
                <div>
                    <img src={Ones} className='img-fluid one_images1' alt='' width='500' height='450' loading='lazy' />
                </div>
                <div>
                <h3 className='banner_head1'>
                    UNDERGRADUATE PROSTHODONTIC 
                    </h3>
                    <h2 className='banner_highHead1'>
                     CONVENTION
                    </h2>
                    <div className='white_back1 mt-4'>
                        <p className='banner_date1'>
                            22nd & 23rd March 2024, Chennai
                        </p>
                    </div>
                </div>
              </div>
            </div>
        </div>
       </div>
    </div>
  )
}

export default Eventsbanner