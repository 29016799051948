import React from 'react';
import About  from '../../assests/home/last.webp';
import './AboutHome.css';
import { Link } from 'react-router-dom';

const AboutLast = () => {
  return (
    <div className='lastmain'>
    <div className='container-fluid col-10'>
     <div className='row  d-flex justify-content-center align-items-center'>
        <div className='col-lg-6 col-12'>
            <img src={About} className='img-fluid lastabout_images center-block d-block mx-auto mx-lg-o' alt='last'  width='100' height='100' loading='lazy' />
        </div>
        <div className='col-lg-6 col-12'>
         <h2 className='lastabout_head mt-4'>
         Sri Ramachandra Dental College and Hospital
         </h2>
         <p className='lastabout_parra mt-4'>
         Sri Ramachandra Dental College and Hospital was started in 1995 as a constituent college of Sri Ramachandra Institute of Higher
          Education and Research.  Utilising a floor space of 2,29,000 sq.ft, the college has 330 well equipped dental chairs to fulfill 
         the needs of the Undergraduates and Postgraduates in every branch of dentistry. There are specially designed laboratories .
         </p>
         <Link to={'/about'} className='link_all'>
         <button className='know_more mt-4 center-block d-block mx-lg-0 mx-auto'>
            Know More
         </button>
         </Link>
        </div>
     </div>
    </div>
    </div>
  )
}

export default AboutLast