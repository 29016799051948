import React from 'react';
import './EventForm.css'
import EventsFormRegistration from '../../components/EventsForm/EventsFormRegistration';
import EventsValid from '../../components/EventsForm/EventsValid';
import Header from '../../components/header/Header';

const EventForm = () => {
  return (
    <>
    <Header />
      <div className='eventformmain'>
    <h3 className='eventformhead'>
        Events Registration
    </h3>
    </div>

    {/* <EventsFormRegistration /> */}
    <EventsValid/>
    </>
  
  )
}

export default EventForm