import React from 'react'
import Eventsbanner from '../../components/events/Eventsbanner'
import EventsSplit from '../../components/events/EventsSplit'
import Header from '../../components/header/Header'

const Events = () => {
  return (
    <div>
      <Header />
      <Eventsbanner />  
      <EventsSplit/>
    </div>
  )
}

export default Events