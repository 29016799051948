import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { useNavigate } from 'react-router-dom';
import './RegisterForm.css'
import Select from 'react-select';

const RegisterForm = () => {

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [collegenames, setCollegenames] = useState("");
  const [collegename, setCollegename] = useState("");
  const [password, setPassword] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [file1, setFile1] = useState(null);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState(false);
  const [year, setYear] = useState(false);
  const [checked, setChecked] = useState(false);
  const [food, setFood] = useState("");
  const [icon, setIcon] = useState(eyeOff);
  const [type, setType] = useState('password');
  const [errorMsg, setErrorMsg] = useState("")
  const [errorMsg1, setErrorMsg1] = useState("")
  const navigate = useNavigate();
  const options = [
    { value: 'Anil Neerukonda Institute of Dental Sciences, Visakhapatnam, Andhra Pradesh', label: 'Anil Neerukonda Institute of Dental Sciences, Visakhapatnam, Andhra Pradesh' },
    { value: 'C.K.S. Teja Institute of Dental Sciences & Research, Tirupati, Andhra Pradesh', label: 'C.K.S. Teja Institute of Dental Sciences & Research, Tirupati, Andhra Pradesh' },
    { value: 'Care Dental College, Guntur, Andhra Pradesh', label: 'Care Dental College, Guntur, Andhra Pradesh' },
    { value: 'Drs. Sudha & Nageswara Rao Siddhartha Institute of Dental Sciences, Gannavaram Mandalam,Andhra Pradesh', label: 'Drs. Sudha & Nageswara Rao Siddhartha Institute of Dental Sciences, Gannavaram Mandalam,Andhra Pradesh' },
    { value: 'G. Pulla Reddy Dental College & Hospital, Kurnool, Andhra Pradesh', label: 'Option 2G. Pulla Reddy Dental College & Hospital, Kurnool, Andhra Pradesh' },
    { value: 'Gitam Dental College & Hospital, Vishakhapatnam,Andhra Pradesh ', label: 'Option 2Gitam Dental College & Hospital, Vishakhapatnam,Andhra Pradesh' },
    { value: 'Govt. Dental College & Hospital, Vijayawada, Andhra Pradesh ', label: 'Govt. Dental College & Hospital, Vijayawada, Andhra Pradesh' },
    { value: 'Govt. Dental College, RIMS, Kadapa, Andhra Pradesh ', label: 'Govt. Dental College, RIMS, Kadapa,Andhra Pradesh ' },
    { value: 'GSL Dental College & Hospital, Rajahmundry Andhra Pradesh', label: 'GSL Dental College & Hospital, Rajahmundry Andhra Pradesh' },
    { value: 'KIMS Dental College, Amalapuram,Andhra Pradesh ', label: 'KIMS Dental College, Amalapuram,Andhra Pradesh ' },
    { value: 'Lenora Institute of Dental Sciences, Rajahmundry, Andhra Pradesh', label: 'Lenora Institute of Dental Sciences, Rajahmundry, Andhra Pradesh' },
    { value: 'Narayana Dental College & Hospital, Nellore, Andhra Pradesh ', label: 'Narayana Dental College & Hospital, Nellore, Andhra Pradesh' },
    { value: 'Nimra Institute of Dental Sciences,Andhra Pradesh ', label: 'Nimra Institute of Dental Sciences,Andhra Pradesh ' },
    { value: 'Sibar Institute of Dental Sciences, Guntur, Andhra Pradesh ', label: 'Sibar Institute of Dental Sciences, Guntur, Andhra Pradesh ' },
    { value: 'Sree Sai Dental College & Research Institute, Srikakulam,Andhra Pradesh ', label: 'Sree Sai Dental College & Research Institute, Srikakulam,Andhra Pradesh' },
    { value: 'St. Joseph Dental College, Duggirala,Andhra Pradesh ', label: 'St. Joseph Dental College, Duggirala,Andhra Pradesh' },
    { value: 'Vishnu Dental College, Bhimavaram, Andhra Pradesh', label: 'Vishnu Dental College, Bhimavaram, Andhra Pradesh' },
    { value: 'Govt. Dental College, Dibrugarh, Assam, Assam', label: 'Govt. Dental College, Dibrugarh, Assam,Assam' },
    { value: 'Govt. Dental College, Silchar, Assam, Assam', label: 'Govt. Dental College, Silchar, Assam, Assam' },
    { value: 'Regional Dental College, Guwahati, Assam', label: 'Regional Dental College, Guwahati, Assam' },
    { value: 'Buddha Institute of Dental Sciences & Hospital, Kankarbagh, Patna,Bihar ', label: 'Buddha Institute of Dental Sciences & Hospital, Kankarbagh, Patna,Bihar ' },
    { value: 'Dr. B.R. Ambedkar Institute of Dental Sciences & Hospital, Patna, Bihar', label: 'Dr. B.R. Ambedkar Institute of Dental Sciences & Hospital, Patna, Bihar' },
    { value: 'Government Dental College & Hospital, Nalanda, Bihar ', label: 'Government Dental College & Hospital, Nalanda, Bihar' },
    { value: 'Mithila Minority Dental College & Hospital, Darbhanga, Bihar', label: 'Mithila Minority Dental College & Hospital, Darbhanga, Bihar' },
    { value: 'Patna Dental College & Hospital, Patna, Bihar', label: 'Patna Dental College & Hospital, Patna, Biha' },
    { value: 'Sarjug Dental College, Darbhanga, Bihar', label: 'Sarjug Dental College, Darbhanga, Bihar' },
    { value: 'Dr. Harvansh Singh Judge Institute of Dental Sciences & Hospital, Chandigarh, Chandigarh ', label: 'Dr. Harvansh Singh Judge Institute of Dental Sciences & Hospital, Chandigarh, Chandigarh ' },
    { value: 'Chattisgarh Dental College & Research Institute, Rajnandgaon, Chhattisgarh', label: 'Chattisgarh Dental College & Research Institute, Rajnandgaon, Chhattisgarh' },
    { value: 'Govt. Dental College, Raipur, Chhattisgarh, Chhattisgarh', label: 'Govt. Dental College, Raipur, Chhattisgarh, Chhattisgarh' },
    { value: 'Maitri College of Dentistry and Research Centre, Durg, Chhattisgarh ', label: 'Maitri College of Dentistry and Research Centre, Durg, Chhattisgarh' },
    { value: 'New Horizon Dental College & Research Instiute, Bilaspur, Chhattisgarh', label: 'New Horizon Dental College & Research Instiute, Bilaspur, Chhattisgarh' },
    { value: 'Rungta College of Dental Sciences & Research, Bhilai, Chhattisgarh', label: 'Rungta College of Dental Sciences & Research, Bhilai, Chhattisgarh' },
    { value: 'Triveni Institute of Dental Sciences, Hosptial & Research Centre, Bilaspur, Chhattisgarh', label: 'Triveni Institute of Dental Sciences, Hosptial & Research Centre, Bilaspur, Chhattisgarh' },
    { value: 'Vaidik Dental College and Research Centre, Daman & Diu', label: 'Vaidik Dental College and Research Centre, Daman & Diu' },
    { value: 'Army Hospital (Research and Referral) Delhi Cantt, Delhi, Delhi ', label: 'Army Hospital (Research and Referral) Delhi Cantt, Delhi, Delhi' },
    { value: 'ESIC Dental College, Rohini, New Delhi,Delhi ', label: 'ESIC Dental College, Rohini, New Delhi,Delhi' },
    { value: 'Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Delhi', label: 'Faculty of Dentistry, Jamia Millia Islamia, New Delhi,Delhi' },
    { value: 'Lady Hardinge Medical College & Smt. Sucheta Kriplani Hospital New Delhi, Delhi  ', label: 'Lady Hardinge Medical College & Smt. Sucheta Kriplani Hospital New Delhi, Delhi' },
    { value: 'Maulana Azad Dental College & Hospital, New Delhi, Delhi', label: 'Maulana Azad Dental College & Hospital, New Delhi, Delhi' },
    { value: 'University College of Medical Sciences, Dilshad Garden,Delhi ', label: 'University College of Medical Sciences, Dilshad Garden,Delhi' },
    { value: 'Goa Dental College & Hospital, Goa, Goa ', label: 'Goa Dental College & Hospital, Goa, Goa' },
    { value: 'Ahmedabad Dental College & Hospital, Gandhinagar,Gujarat', label: 'Ahmedabad Dental College & Hospital, Gandhinagar,Gujarat' },
    { value: 'AMC Dental College, Ahmedabad, Gujarat', label: 'AMC Dental College, Ahmedabad, Gujarat' },
    { value: 'College of Dental Sciences and Research Centre, Ahmedabad, Gujarat', label: 'College of Dental Sciences and Research Centre, Ahmedabad, Gujarat' },
    { value: 'College of Dental Sciences, Amargadh, Gujarat', label: 'College of Dental Sciences, Amargadh, Gujara' },
    { value: 'Faculty of Dental Science, Dharmsinh Desai University, Nadiad,Gujarat', label: 'Faculty of Dental Science, Dharmsinh Desai University, Nadiad,Gujarat' },
    { value: 'Goenka Research Institute of Dental Sciences, Gandhinagar,Gujarat', label: 'Goenka Research Institute of Dental Sciences, Gandhinagar,Gujarat' },
    { value: 'Govt. Dental College & Hospital, Ahmedabad, Gujarat', label: 'Govt. Dental College & Hospital, Ahmedabad, Gujarat' },
    { value: 'K.M. Shah Dental College & Hospital, Vadodara, Gujarat', label: 'K.M. Shah Dental College & Hospital, Vadodara, Gujarat' },
    { value: 'Karnavati School of Dentistry, Gandhinagar,Gujarat ', label: 'Karnavati School of Dentistry, Gandhinagar,Gujarat' },
    { value: 'Manubhai Patel Dental College & Dental Hospital & SSR General Hospital, Vadodara,Gujarat ', label: 'Manubhai Patel Dental College & Dental Hospital & SSR General Hospital, Vadodara,Gujarat' },
    { value: 'Narsinhbhai Patel Dental College & Hospital, Visnagar,Gujarat ', label: 'Narsinhbhai Patel Dental College & Hospital, Visnagar,Gujarat' },
    { value: 'Siddhpur Dental College, Patan,Gujarat ', label: 'Siddhpur Dental College, Patan,Gujarat' },
    { value: 'D.A.V. Centenary Dental College, Yamuna nagar, Haryana ', label: 'D.A.V. Centenary Dental College, Yamuna nagar, Haryana' },
    { value: 'Jan Nayak Ch. Devi Lal Dental College, Sirsa,Haryana ', label: 'Jan Nayak Ch. Devi Lal Dental College, Sirsa,Haryana' },
    { value: 'Maharishi Markandeshwar College of Dental Sciences & Research, Mullana,Haryana ', label: 'Maharishi Markandeshwar College of Dental Sciences & Research, Mullana,Haryana ' },
    { value: 'Manav Rachana Dental College, Faridabad, Haryana ', label: 'Manav Rachana Dental College, Faridabad, Haryana ' },
    { value: 'PDM Dental College & Research Institute, Jhajjar,Haryana ', label: 'PDM Dental College & Research Institute, Jhajjar,Haryana' },
    { value: 'Post Graduate Institute of Dental Sciences, Rohtak,Haryana', label: 'Post Graduate Institute of Dental Sciences, Rohtak,Haryana' },
    { value: 'Sri Govind Tricentenary Dental College, Hospital & Research Institute,Haryana ', label: 'Sri Govind Tricentenary Dental College, Hospital & Research Institute,Haryana' },
    { value: 'Sudha Rustagi College of Dental Sciences & Research,Faridabad,Haryana ', label: 'Sudha Rustagi College of Dental Sciences & Research,Faridabad,Haryana' },
    { value: 'Swami Devi Dyal Hospital & Dental College, Panchkula,Haryana ', label: 'Swami Devi Dyal Hospital & Dental College, Panchkula,Haryana' },
    { value: 'Yamuna Institute of Dental Sciences & Research, Yamuna Nagar,Haryana', label: 'Yamuna Institute of Dental Sciences & Research, Yamuna Nagar,Haryana' },
    { value: 'Bhojia Dental College & Hospital, Nalagarh,Himachal Pardesh', label: 'Bhojia Dental College & Hospital, Nalagarh,Himachal Pradesh' },
    { value: 'H.P. Govt. Dental College & Hospital, Shimla, Himachal Pradesh ', label: 'H.P. Govt. Dental College & Hospital, Shimla, Himachal Pradesh ' },
    { value: 'Himachal Dental College, Sunder Nagar, Himachal Pradesh ', label: 'Himachal Dental College, Sunder Nagar, Himachal Pradesh ' },
    { value: 'Himachal Institute of Dental Sciences, Poanta Sahib, Himachal Pradesh ', label: 'Himachal Institute of Dental Sciences, Poanta Sahib, Himachal Pradesh ' },
    { value: 'M.N.D.A.V. Dental College & Hospital, Solan, Himachal Pradesh ', label: 'M.N.D.A.V. Dental College & Hospital, Solan, Himachal Pradesh ' },
    { value: 'Govt. Dental College, Srinagar,  Jammu & Kashmir ', label: 'Govt. Dental College, Srinagar,  Jammu & Kashmir' },
    { value: 'Indira Gandhi Government Dental College, Jammu,  Jammu & Kashmir', label: 'Indira Gandhi Government Dental College, Jammu,  Jammu & Kashmir' },
    { value: 'Institute of Dental Sciences, Sehora,  Jammu & Kashmir', label: 'Institute of Dental Sciences, Sehora,  Jammu & Kashmir' },
    { value: 'Awadh Dental College & Hospital, Jamshedpur, Jharkhand', label: 'optioAwadh Dental College & Hospital, Jamshedpurn1, Jharkhand' },
    { value: 'optDental Institute Rajendra Institute of Medical Sciences, Ranchiion1, Jharkhand', label: 'Dental Institute Rajendra Institute of Medical Sciences, Ranchi, Jharkhand' },
    { value: 'Hazaribag College of Dental Sciences and Hospital, Hazaribag, Jharkhand', label: 'Hazaribag College of Dental Sciences and Hospital, Hazaribag, Jharkhand' },
    { value: 'Vananchal Dental College & Hospital, Garhwa, Jharkhand', label: 'Vananchal Dental College & Hospital, Garhwa, Jharkhand' },
    { value: 'A.B. Shetty Memorial Institute of Dental Sciences, Mangalore, Karnataka', label: 'A.B. Shetty Memorial Institute of Dental Sciences, Mangalore, Karnataka' },
    { value: 'A.J. Institute of Dental Sciences, Mangalore, Karnataka', label: 'A.J. Institute of Dental Sciences, Mangalore, Karnataka' },
    { value: 'Air Force Institute of Dental Sciences, Bangalore, Karnataka', label: 'Air Force Institute of Dental Sciences, Bangalore, Karnataka' },
    { value: 'Al Ameen Dental College & Hospital, Bijapur, Karnataka', label: 'Al Ameen Dental College & Hospital, Bijapur, Karnataka' },
    { value: 'Al-Badar Rural Dental College & Hospital, Gulbarga, Karnataka', label: 'Al-Badar Rural Dental College & Hospital, Gulbarga, Karnataka' },
    { value: 'AME’s Dental College & Hospital, Raichur, Karnataka', label: 'AME’s Dental College & Hospital, Raichur, Karnataka' },
    { value: 'Bangalore Institute of Dental Sciences & Hospital, Bangalore, Karnataka', label: 'Bangalore Institute of Dental Sciences & Hospital, Bangalore, Karnataka' },
    { value: 'Bapuji Dental College & Hospital, Davangere, Karnataka', label: 'Bapuji Dental College & Hospital, Davangere, Karnataka' },
    { value: 'College of Dental Sciences, Davangere, Karnataka', label: 'College of Dental Sciences, Davangere, Karnataka' },
    { value: 'Coorg Institute of Dental Sciences, Virajpat, Karnataka', label: 'Coorg Institute of Dental Sciences, Virajpat, Karnataka' },
    { value: 'D.A. Pandu Memorial R.V. Dental College, Bangalore, Karnataka', label: 'D.A. Pandu Memorial R.V. Dental College, Bangalore, Karnataka' },
    { value: 'Dayanand Sagar College of Dental Sciences, Bangalore, Karnataka', label: 'Dayanand Sagar College of Dental Sciences, Bangalore, Karnataka' },
    { value: 'Dr. Syamala Reddy Dental College, Hospital & Research Centre, Bangalore, Karnataka', label: 'Dr. Syamala Reddy Dental College, Hospital & Research Centre, Bangalore, Karnataka' },
    { value: 'ESIC Dental College, Gulbarga, Karnataka', label: 'ESIC Dental College, Gulbarga, Karnataka' },
    { value: 'Farooqia Dental College & Hospital, Mysore, Karnataka', label: 'Farooqia Dental College & Hospital, Mysore, Karnataka' },
    { value: 'Govt. Dental College & Research Institute, Bellary, Karnataka', label: 'Govt. Dental College & Research Institute, Bellary, Karnataka' },
    { value: 'Govt. Dental College, Bangalore, Karnataka', label: 'Govt. Dental College, Bangalore, Karnataka' },
    { value: 'H.K.D.E.Ts Dental College, Hospital & Research Institute, Humnabad, Karnataka', label: 'H.K.D.E.Ts Dental College, Hospital & Research Institute, Humnabad, Karnataka' },
    { value: "H.K.E. Society's, S. Nijalingappa Institute of Dental Sciences & Research, Karnataka", label: "H.K.E. Society's, S. Nijalingappa Institute of Dental Sciences & Research, Karnataka" },
    { value: 'JSS Dental College & Hospital, Mysore, Karnataka', label: 'JSS Dental College & Hospital, Mysore, Karnataka' },
    { value: 'K.G.F. College of Dental Sciences & Hospital, KGF, Karnataka', label: 'K.G.F. College of Dental Sciences & Hospital, KGF, Karnataka' },
    { value: 'K.V.G. Dental College & Hospital, Sullia, Karnataka', label: 'K.V.G. Dental College & Hospital, Sullia, Karnataka' },
    { value: 'KLE Society’s Institute of Dental Sciences,Bangalore, Karnataka', label: 'KLE Society’s Institute of Dental Sciences,Bangalore, Karnataka' },
    { value: 'KLE Vishwanath Katti Institute of Dental Sciences, Belgaum, Karnataka', label: 'KLE Vishwanath Katti Institute of Dental Sciences, Belgaum, Karnataka' },
    { value: 'Krishnadevaraya College of Dental Sciences & Hospital, Bangalore, Karnataka', label: 'Krishnadevaraya College of Dental Sciences & Hospital, Bangalore, Karnataka' },
    { value: 'M.R. Ambedkar Dental College & Hospital, Bangalore, Karnataka', label: 'M.R. Ambedkar Dental College & Hospital, Bangalore, Karnataka' },
    { value: 'M.S. Ramaiah Dental College, Bangalore, Karnataka', label: 'M.S. Ramaiah Dental College, Bangalore, Karnataka' },
    { value: 'Maaruti College of Dental Sciences & Research Centre, Bangalore, Karnataka', label: 'Maaruti College of Dental Sciences & Research Centre, Bangalore, Karnataka' },
    { value: 'Manipal College of Dental Sciences, Mangalore, Karnataka', label: 'Manipal College of Dental Sciences, Mangalore, Karnataka' },
    { value: 'Manipal College of Dental Sciences, Manipal, Karnataka', label: 'Manipal College of Dental Sciences, Manipal, Karnataka' },
    { value: 'Maratha Mandal’s Dental College & Research Centre, Belgaum, Karnataka', label: 'Maratha Mandal’s Dental College & Research Centre, Belgaum, Karnataka' },
    { value: 'Navodaya Dental College, Raichur, Karnataka', label: 'Navodaya Dental College, Raichur, Karnataka' },
    { value: 'P.M.N.M. Dental College & Hospital, Bagalkot, Karnataka', label: 'P.M.N.M. Dental College & Hospital, Bagalkot, Karnataka' },
    { value: 'Rajarajeswari Dental College & Hospital, Bangalore, Karnataka', label: 'Rajarajeswari Dental College & Hospital, Bangalore, Karnataka' },
    { value: 'S.B. Patil Dental College & Hospital, Bidar, Karnataka', label: 'S.B. Patil Dental College & Hospital, Bidar, Karnataka' },
    { value: 'S.J.M. Dental College & Hospital, Chitradurga, Karnataka', label: 'S.J.M. Dental College & Hospital, Chitradurga, Karnataka' },
    { value: 'SDM College of Dental Sciences & Hospital, Dharwad, Karnataka', label: 'SDM College of Dental Sciences & Hospital, Dharwad, Karnataka' },
    { value: 'Sharavathi Dental College & Hospital, Shimoga, Karnataka', label: 'Sharavathi Dental College & Hospital, Shimoga, Karnataka' },
    { value: 'Sri Hasanamba Dental College & Hospital, Hassan, Karnataka', label: 'Sri Hasanamba Dental College & Hospital, Hassan, Karnataka' },
    { value: 'Sri Rajiv Gandhi College of Dental Sciences & Hospital, Bangalore, Karnataka', label: 'Sri Rajiv Gandhi College of Dental Sciences & Hospital, Bangalore, Karnataka' },
    { value: 'Sri Siddhartha Dental College, Tumkur, Karnataka', label: 'Sri Siddhartha Dental College, Tumkur, Karnataka' },
    { value: 'Sri Venkateshwara Dental College & Hospital, Bangalore, Karnataka', label: 'Sri Venkateshwara Dental College & Hospital, Bangalore, Karnataka' },
    { value: 'Srinivas Institute of Dental Sciences, Mangalore, Karnataka', label: 'Srinivas Institute of Dental Sciences, Mangalore, Karnataka' },
    { value: 'Subbaiah Institute of Dental Sciences, Purale, Shimoga, Karnataka', label: 'Subbaiah Institute of Dental Sciences, Purale, Shimoga, Karnataka' },
    { value: 'The Oxford Dental College, Bangalore, Karnataka', label: 'The Oxford Dental College, Bangalore, Karnataka' },
    { value: 'V.S. Dental College, Bangalore, Karnataka', label: 'V.S. Dental College, Bangalore, Karnataka' },
    { value: 'Vydehi Institute of Dental Sciences & Research, Bangalore, Karnataka', label: 'Vydehi Institute of Dental Sciences & Research, Bangalore, Karnataka' },
    { value: 'Yenepoya Dental College & Hospital, Mangalore, Karnataka', label: 'Yenepoya Dental College & Hospital, Mangalore, Karnataka' },
    { value: 'Al-Azhar Dental College, Kerala, Kerala,', label: 'Al-Azhar Dental College, Kerala, Kerala,' },
    { value: 'Amrita School of Dentistry, Kochi, Kerala,', label: 'Amrita School of Dentistry, Kochi, Kerala,' },
    { value: 'Annoor Dental College & Hospital, Ernakulam, Kerala,', label: 'Annoor Dental College & Hospital, Ernakulam, Kerala,' },
    { value: 'Azeezia College of Dental Sciences & Research, Kollam, Kerala,', label: 'Azeezia College of Dental Sciences & Research, Kollam, Kerala,' },
    { value: 'Century International Institute of Dental Science & Research Center, Kasaragod, Kerala,', label: 'Century International Institute of Dental Science & Research Center, Kasaragod, Kerala,' },
    { value: 'Educare Institute of Dental Sciences, Malappuram, Kerala,', label: 'Educare Institute of Dental Sciences, Malappuram, Kerala,' },
    { value: 'Govt. Dental College, Alappuzha, Kerala,', label: 'Govt. Dental College, Alappuzha, Kerala,' },
    { value: 'Govt. Dental College, Kottayam, Kerala,', label: 'Govt. Dental College, Kottayam, Kerala,' },
    { value: 'Govt. Dental College, Kozhikode, Kerala,', label: 'Govt. Dental College, Kozhikode, Kerala,' },
    { value: 'Govt. Dental College, Thrissur, Kerala,', label: 'Govt. Dental College, Thrissur, Kerala,' },
    { value: 'Govt. Dental College, Trivandrum, Kerala,', label: 'Govt. Dental College, Trivandrum, Kerala,' },
    { value: 'Indira Gandhi Institute of Dental Sciences, Kothamangalam, Kerala,', label: 'Indira Gandhi Institute of Dental Sciences, Kothamangalam, Kerala,' },
    { value: 'Kannur Dental College, kannur, Kerala,', label: 'Kannur Dental College, kannur, Kerala,' },
    { value: 'KMCT Dental College, Calicut, Kerala,', label: 'KMCT Dental College, Calicut, Kerala,' },
    { value: 'Malabar Dental College & Research Centre, Malappuram, Kerala,', label: 'Malabar Dental College & Research Centre, Malappuram, Kerala,' },
    { value: 'Mar Baselios Dental College, Kothamangalam, Kerala,', label: 'MES Dental College, Malappuram, Kerala' },
    { value: 'Noorul Islam College of Dental Sciences, Trivandrum, Kerala', label: 'Noorul Islam College of Dental Sciences, Trivandrum, Kerala' },
    { value: 'Pariyaram Dental College, Academy of Medical Sciences, Kannur, Kerala', label: 'Pariyaram Dental College, Academy of Medical Sciences, Kannur, Kerala' },
    { value: 'PMS College of Dental Science & Research, Thiruvananthpuram, Kerala', label: 'PMS College of Dental Science & Research, Thiruvananthpuram, Kerala' },
    { value: 'PSM College of Dental Sciences & Research, Trichur, Kerala', label: 'PSM College of Dental Sciences & Research, Trichur, Kerala' },
    { value: 'Pushpagiri College of Dental Sciences, Tiruvalla1, Kerala', label: 'Pushpagiri College of Dental Sciences, Tiruvalla, Kerala' },
    { value: 'Royal Dental College, kerala, Kerala', label: 'Royal Dental College, kerala, Kerala' },
    { value: 'Sree Anjaneya Medical Trust, Calicut, Kerala', label: 'Sree Anjaneya Medical Trust, Calicut, Kerala' },
    { value: 'Sri Sankara Dental College, Thiruvananthapuram, Kerala', label: 'Sri Sankara Dental College, Thiruvananthapuram, Kerala' },
    { value: 'St. Gregorios Dental College, Ernakulam, Kerala', label: 'St. Gregorios Dental College, Ernakulam, Kerala' },
    { value: 'Travancore Dental College, Kerala', label: 'Travancore Dental College, Kerala' },
    { value: 'Bhabha College of Dental Sciences, Bhopal, Madhya', label: 'Bhabha College of Dental Sciences, Bhopal, Madhya' },
    { value: 'College of Dental Sciences & Hospital, Indore, Madhya', label: 'College of Dental Sciences & Hospital, Indore, Madhya' },
    { value: 'College of Dentistry- Indore, Madhya', label: 'College of Dentistry- Indore, Madhya' },
    { value: 'Guru Gobind Singh College of Dental Science & Research Centre, Burhanpur, Madhya', label: 'Guru Gobind Singh College of Dental Science & Research Centre, Burhanpur, Madhya' },
    { value: 'Hitkarini Dental College & Hospital, Jabalpur, Madhya', label: 'Hitkarini Dental College & Hospital, Jabalpur, Madhya' },
    { value: 'Index Institute of Dental Sciences, Indore, Madhya', label: 'Index Institute of Dental Sciences, Indore, Madhya' },
    { value: 'Institute of Dental Education & Advance Studies (IDEAS), Gwalior, Madhya pradesh', label: 'Institute of Dental Education & Advance Studies (IDEAS), Gwalior, Madhya pradesh' },
    { value: 'Maharana Pratap College of Dentistry & Research Centre, Gwalior, Madhya pradesh', label: 'Maharana Pratap College of Dentistry & Research Centre, Gwalior, Madhya pradesh' },
    { value: 'Mansarover Dental College, Bhopal, Madhya pradesh', label: 'Mansarover Dental College, Bhopal, Madhya pradesh' },
    { value: 'Modern Dental College & Research Centre, Indore, Madhya pradesh', label: 'Modern Dental College & Research Centre, Indore, Madhya pradesh' },
    { value: 'People’s Dental Academy, Bhopal, Madhya pradesh', label: 'People’s Dental Academy, Bhopal, Madhya pradesh' },
    { value: 'Peoples College of Dental Sciences & Research Centre, Bhopal, Madhya pradesh', label: 'Peoples College of Dental Sciences & Research Centre, Bhopal, Madhya pradesh' },
    { value: 'Rishiraj College of Dental Sciences & Research Centre, Bhopal, Madhya pradesh', label: 'Rishiraj College of Dental Sciences & Research Centre, Bhopal, Madhya pradesh' },
    { value: 'RKDF Dental College & Research Centre, Bhopal, Madhya pradesh', label: 'RKDF Dental College & Research Centre, Bhopal, Madhya pradesh' },
    { value: 'Sri Aurobindo College of Dentistry, Indore, Madhya pradesh', label: 'Sri Aurobindo College of Dentistry, Indore, Madhya pradesh' },
    { value: 'Aditya Dental College, Beed, Maharashtra', label: 'Aditya Dental College, Beed, Maharashtra' },
    { value: 'Annasaheb Chudaman Patil Memorial Dental College, Dhule, Maharashtra', label: 'Annasaheb Chudaman Patil Memorial Dental College, Dhule, Maharashtra' },
    { value: 'Armed Forces Medical College, Pune-1, Maharashtra', label: 'Armed Forces Medical College, Pune-1, Maharashtra' },
    { value: 'Bharati Vidyapeeth Dental College & Hospital, Navi Mumbai, Maharashtra', label: 'Bharati Vidyapeeth Dental College & Hospital, Navi Mumbai, Maharashtra' },
    { value: 'Bharati Vidyapeeth Dental College & Hospital, Pune, Maharashtra', label: 'Bharati Vidyapeeth Dental College & Hospital, Pune, Maharashtra' },
    { value: 'Bharati Vidyapeeth Dental College & Hospital, Sangli, Maharashtra', label: 'Bharati Vidyapeeth Dental College & Hospital, Sangli, Maharashtra' },
    { value: 'Chatrapati Shahu Maharaj Shikshan Sanstha’s Dental College & Hospital, Aurangabad, Maharashtra', label: 'Chatrapati Shahu Maharaj Shikshan Sanstha’s Dental College & Hospital, Aurangabad, Maharashtra' },
    { value: 'D.Y. Patil Dental School, Pune, Maharashtra', label: 'D.Y. Patil Dental School, Pune, Maharashtra' },
    { value: 'Dr. D.Y. Patil Dental College & Hospital, Pune, Maharashtra', label: 'Dr. D.Y. Patil Dental College & Hospital, Pune, Maharashtra' },
    { value: 'Dr. Hedgewar Smruti Rugna Seva Mandals Dental College & Hospital, Hingoli, Maharashtra', label: 'Dr. Hedgewar Smruti Rugna Seva Mandals Dental College & Hospital, Hingoli, Maharashtra' },
    { value: 'Dr. Rajesh Ramdasji Kambe Dental College & Hospital, Akola, Maharashtra', label: 'Dr. Rajesh Ramdasji Kambe Dental College & Hospital, Akola, Maharashtra' },
    { value: 'Govt. Dental College & Hospital, Aurangabad, Maharashtra', label: 'Govt. Dental College & Hospital, Aurangabad, Maharashtra' },
    { value: 'Govt. Dental College & Hospital, Mumbai, Maharashtra', label: 'Govt. Dental College & Hospital, Mumbai, Maharashtra' },
    { value: 'Govt. Dental College & Hospital, Nagpur, Maharashtra', label: 'Govt. Dental College & Hospital, Nagpur, Maharashtra' },
    { value: "Late Shri Yashwantrao Chavan Memorial Medical & Rural Development Foundation's Dental College & Hospital, Ahmednagar, Maharashtra", label: "Late Shri Yashwantrao Chavan Memorial Medical & Rural Development Foundation's Dental College & Hospital, Ahmednagar, Maharashtra" },
    { value: 'M.A. Rangoonwala College of Dental Sciences & Research Centre, Pune, Maharashtra', label: 'M.A. Rangoonwala College of Dental Sciences & Research Centre, Pune, Maharashtra' },
    { value: 'Maharashtra Institute of Dental Sciences & Research (Dental College), Maharashtra', label: 'Maharashtra Institute of Dental Sciences & Research (Dental College), Maharashtra' },
    { value: 'Mahatma Gandhi Missions Dental College & Hospital, Kamothe, Navi Mumbai, Maharashtra', label: 'Mahatma Gandhi Missions Dental College & Hospital, Kamothe, Navi Mumbai, Maharashtra' },
    { value: 'Mahatma Gandhi Vidya Mandir’s Dental College & Hospital, Nashik, Maharashtra', label: 'Mahatma Gandhi Vidya Mandir’s Dental College & Hospital, Nashik, Maharashtra' },
    { value: 'Nair Hospital Dental College, Mumbai, Maharashtra', label: 'Nair Hospital Dental College, Mumbai, Maharashtra' },
    { value: 'Nanded Rural Dental College & Research Center, Nanded, Maharashtra', label: 'Nanded Rural Dental College & Research Center, Nanded, Maharashtra' },
    { value: 'Padmashree Dr. D.Y. Patil Dental College & Hospital, Navi Mumbai, Maharashtra', label: 'Padmashree Dr. D.Y. Patil Dental College & Hospital, Navi Mumbai, Maharashtra' },
    { value: 'Pandit Dindayal Upadhyay Dental College, Solapur, Maharashtra', label: 'Pandit Dindayal Upadhyay Dental College, Solapur, Maharashtra' },
    { value: 'Rural Dental College, Loni, Maharashtra', label: 'Rural Dental College, Loni, Maharashtra' },
    { value: 'S.M.B.T. Dental College & Hospital, Amrutnagar, Maharashtra', label: 'S.M.B.T. Dental College & Hospital, Amrutnagar, Maharashtra' },
    { value: 'Saraswati Danwantri Dental College & Hospital, Parbhani, Maharashtra', label: 'Saraswati Danwantri Dental College & Hospital, Parbhani, Maharashtra' },
    { value: 'School of Dental Sciences, Krishna Institute of Medical Sciences, Karad, Maharashtra', label: 'School of Dental Sciences, Krishna Institute of Medical Sciences, Karad, Maharashtra' },
    { value: 'Sharad Pawar Dental College & Hospital, Wardha, Maharashtra', label: 'Sharad Pawar Dental College & Hospital, Wardha, Maharashtra' },
    { value: 'Sinhgad Dental College & Hospital, Pune, Maharashtra', label: 'Sinhgad Dental College & Hospital, Pune, Maharashtra' },
    { value: 'SMBT Institute of Dental Sciences and Research, Nandihills, Nashik, Maharashtra', label: 'SMBT Institute of Dental Sciences and Research, Nandihills, Nashik, Maharashtra' },
    { value: 'Swargiya Dadasaheb Kalmegh Smruti Dental College & Hospital, Nagpur, Maharashtra', label: 'Swargiya Dadasaheb Kalmegh Smruti Dental College & Hospital, Nagpur, Maharashtra' },
    { value: 'Tatyasaheb Kore Dental College & Research Centre, New Pargaon, Maharashtra', label: 'Tatyasaheb Kore Dental College & Research Centre, New Pargaon, Maharashtra' },
    { value: 'Terna Dental College & Hospital, Navi Mumbai, Maharashtra', label: 'Terna Dental College & Hospital, Navi Mumbai, Maharashtra' },
    { value: 'Vasantdada Patil Dental College and Hospital, Sangli, Maharashtra', label: 'Vasantdada Patil Dental College and Hospital, Sangli, Maharashtra' },
    { value: 'Vidarbha Youth Welfare Society’s Dental College & Hospital, Amravati, Maharashtra', label: 'Vidarbha Youth Welfare Society’s Dental College & Hospital, Amravati, Maharashtra' },
    { value: 'VSPM’s Dental College & Research Centre, Nagpur, Maharashtra', label: 'VSPM’s Dental College & Research Centre, Nagpur, Maharashtra' },
    { value: 'Yerala Medical Trust & Research Centre’s Dental College & Hospital, Navi Mumbai, Maharashtra', label: 'Yerala Medical Trust & Research Centre’s Dental College & Hospital, Navi Mumbai, Maharashtra' },
    { value: 'Yogita Dental College & Hospital, Ratnagiri, Maharashtra', label: 'Yogita Dental College & Hospital, Ratnagiri, Maharashtra' },
    { value: 'Dental College, Jawaharlal Nehru Institute of Medical Sciences, Manipur', label: 'Dental College, Jawaharlal Nehru Institute of Medical Sciences, Manipur' },
    { value: 'Dental College, RIMS, Imphal, Manipur', label: 'Dental College, RIMS, Imphal, Manipur' },
    { value: 'Hi-Tech Dental College & hospital, Bhubaneswar, Orissa', label: 'Hi-Tech Dental College & hospital, Bhubaneswar, Orissa' },
    { value: 'Institute of Dental Sciences, Bhubaneswar, Orissa', label: 'Institute of Dental Sciences, Bhubaneswar, Orissa' },
    { value: 'Kalinga Institute of Dental Sciences, Bhubaneswar, Orissa', label: 'Kalinga Institute of Dental Sciences, Bhubaneswar, Orissa' },
    { value: 'S.C.B. Dental College & Hospital, Cuttack, Orissa', label: 'S.C.B. Dental College & Hospital, Cuttack, Orissa' },
    { value: 'Indira Gandhi Institute of Dental Sciences, Pondicherry, Pondichery', label: 'Indira Gandhi Institute of Dental Sciences, Pondicherry, Pondichery' },
    { value: 'Mahatma Gandhi Post Graduate Institute of Dental Sciences, Puducherry, Pondichery', label: 'Mahatma Gandhi Post Graduate Institute of Dental Sciences, Puducherry, Pondichery' },
    { value: 'Mahe Institute of Dental Sciences & Hospital, Mahe, Pondichery', label: 'Mahe Institute of Dental Sciences & Hospital, Mahe, Pondichery' },
    { value: 'Sri Venkateshwaraa Dental College, Puducherry, Pondichery', label: 'Sri Venkateshwaraa Dental College, Puducherry, Pondichery' },
    { value: 'Adesh Institute of Dental Sciences & Research, Bathinda, Punjab', label: 'Adesh Institute of Dental Sciences & Research, Bathinda, Punjab' },
    { value: 'Baba Jaswant Singh Dental College Hospital & Research Institute, Ludhiana, Punjab', label: 'Baba Jaswant Singh Dental College Hospital & Research Institute, Ludhiana, Punjab' },
    { value: 'Christian Dental College, Ludhiana, Punjab', label: 'Christian Dental College, Ludhiana, Punjab' },
    { value: 'Dasmesh Institute of Research & Dental Sciences, Faridkot, Punjab', label: 'Dasmesh Institute of Research & Dental Sciences, Faridkot, Punjab' },
    { value: 'Desh Bhagat Dental College & Hospital, Mandi Gobindgarh, Punjab', label: 'Desh Bhagat Dental College & Hospital, Mandi Gobindgarh, Punjab' },
    { value: 'Genesis Institute of Dental Sciences & Research, Ferozepur, Punjab', label: 'Genesis Institute of Dental Sciences & Research, Ferozepur, Punjab' },
    { value: 'Gian Sagar Dental College & Hospital, Punjab', label: 'Gian Sagar Dental College & Hospital, Punjab' },
    { value: 'Govt. Dental College & Hospital, Patiala, Punjab', label: 'Govt. Dental College & Hospital, Patiala, Punjab' },
    { value: 'Guru Nanak Dev Dental College & Research Institute, Sunam, Punjab', label: 'Guru Nanak Dev Dental College & Research Institute, Sunam, Punjab' },
    { value: 'Luxmi Bai Institute of Dental Sciences & Hospital, Patiala, Punjab', label: 'Luxmi Bai Institute of Dental Sciences & Hospital, Patiala, Punjab' },
    { value: 'National Dental College & Hospital, Gulabgarh, Punjab', label: 'National Dental College & Hospital, Gulabgarh, Punjab' },
    { value: 'Pb. Govt. Dental College & Hospital, Amritsar, Punjab', label: 'Pb. Govt. Dental College & Hospital, Amritsar, Punjab' },
    { value: 'Rayat Bahra Dental College, Mohali, Punjab', label: 'Rayat Bahra Dental College, Mohali, Punjab' },
    { value: 'Shaheed Kartar Singh Sarabha Dental College & Hospital, Ludhiana, Punjab', label: 'Shaheed Kartar Singh Sarabha Dental College & Hospital, Ludhiana, Punjab' },
    { value: 'Sri Guru Ram Das Institute of Dental Sciences & Research, Amritsar, Punjab', label: 'Sri Guru Ram Das Institute of Dental Sciences & Research, Amritsar, Punjab' },
    { value: 'Sukhmani Dental College & Hospital, Derabassi, Punjab', label: 'Sukhmani Dental College & Hospital, Derabassi, Punjab' },
    { value: 'Darshan Dental College & Hospital, Udaipur, Punjab', label: 'Darshan Dental College & Hospital, Udaipur, Punjab' },
    { value: 'Darshan Dental College & Hospital, Udaipur, Rajasthan', label: 'Darshan Dental College & Hospital, Udaipur, Rajasthan' },
    { value: 'Daswani Dental College & Research Centre, Kota, Rajasthan', label: 'Daswani Dental College & Research Centre, Kota, Rajasthan' },
    { value: 'Eklavya Dental College & Hospital, Kotputli, Rajasthan', label: 'Eklavya Dental College & Hospital, Kotputli, Rajasthan' },
    { value: 'Geetanjali Dental & Research Institute, Udaipur, Rajasthan', label: 'Geetanjali Dental & Research Institute, Udaipur, Rajasthan' },
    { value: 'Govt. Dental College & Hospital, Jaipur, Rajasthan', label: 'Govt. Dental College & Hospital, Jaipur, Rajasthan' },
    { value: 'Jaipur Dental College, Jaipur, Rajasthan', label: 'Jaipur Dental College, Jaipur, Rajasthan' },
    { value: 'Jodhpur Dental College General Hospital, Jodhpur, Rajasthan', label: 'Jodhpur Dental College General Hospital, Jodhpur, Rajasthan' },
    { value: 'Maharaja Ganga Singh Dental College & Research Centre, Sri Ganganagar, Rajasthan', label: 'Maharaja Ganga Singh Dental College & Research Centre, Sri Ganganagar, Rajasthan' },
    { value: 'Mahatma Gandhi Dental College & Hospital, Sitapura, Jaipur, Rajasthan', label: 'Mahatma Gandhi Dental College & Hospital, Sitapura, Jaipur, Rajasthan' },
    { value: 'NIMS Dental College, Jaipur, Rajasthan', label: 'NIMS Dental College, Jaipur, Rajasthan' },
    { value: 'Pacific Dental College & Research Centre, Rajasthan', label: 'Pacific Dental College & Research Centre, Rajasthan' },
    { value: 'Pacific Dental College, udaipur, Rajasthan', label: 'Pacific Dental College, udaipur, Rajasthan' },
    { value: 'R.R. Dental College & Hospital, Udaipur, Rajasthan', label: 'R.R. Dental College & Hospital, Udaipur, Rajasthan' },
    { value: 'Rajasthan Dental College & Hospital, Jaipur, Rajasthan', label: 'Rajasthan Dental College & Hospital, Jaipur, Rajasthan' },
    { value: 'Surendera Dental College & Research Institute, Sri Ganganagar, Rajasthan', label: 'Surendera Dental College & Research Institute, Sri Ganganagar, Rajasthan' },
    { value: 'Vyas Dental College & Hospital, Jodhpur, Rajasthan', label: 'Vyas Dental College & Hospital, Jodhpur, Rajasthan' },
    { value: 'Adhiparasakthi Dental College & Hospital, Melmaruvathur, Tamil Nadu ', label: 'Adhiparasakthi Dental College & Hospital, Melmaruvathur, Tamil Nadu' },
    { value: 'Asan Memorial Dental College & Hospital, Kancheepuram, Tamil Nadu', label: 'Asan Memorial Dental College & Hospital, Kancheepuram, Tamil Nadu' },
    { value: 'Best Dental Science College, Madurai, Tamil Nadu', label: 'Best Dental Science College, Madurai, Tamil Nadu' },
    { value: 'Chettinad Dental College & Research Institute, Kancheepuram, Tamil Nadu', label: 'Chettinad Dental College & Research Institute, Kancheepuram, Tamil Nadu' },
    { value: 'CSI College of Dental Sciences & Research, Madurai, Tamil Nadu', label: 'CSI College of Dental Sciences & Research, Madurai, Tamil Nadu' },
    { value: 'Dhanalakshmi Srinivasan Dental College, Tamil Nadu', label: 'Dhanalakshmi Srinivasan Dental College, Tamil Nadu' },
    { value: 'Faculty of Dentistry, Rajah Muthiah Dental College & Hospital, Annamalai Nagar, Tamil Nadu', label: 'Faculty of Dentistry, Rajah Muthiah Dental College & Hospital, Annamalai Nagar, Tamil Nadu' },
    { value: 'Government Dental College, Pudukkottai, Tamil Nadu', label: 'Government Dental College, Pudukkottai, Tamil Nadu' },
    { value: 'JKK Natrajah Dental College & Hospital, Komarapalayam, Tamil Nadu', label: 'JKK Natrajah Dental College & Hospital, Komarapalayam, Tamil Nadu' },
    { value: 'K.S.R. Institute of Dental Science & Research, Tiruchengode, Tamil Nadu', label: 'K.S.R. Institute of Dental Science & Research, Tiruchengode, Tamil Nadu' },
    { value: 'Karpaga Vinayaga Institute of Dental Sciences, Kanchipuram, Tamil Nadu', label: 'Karpaga Vinayaga Institute of Dental Sciences, Kanchipuram, Tamil Nadu' },
    { value: 'Madha Dental College & Hospital, Kundrathur, Tamil Nadu', label: 'Madha Dental College & Hospital, Kundrathur, Tamil Nadu' },
    { value: 'Meenakshi Ammal Dental College & Hospital, Chennai, Tamil Nadu', label: 'Meenakshi Ammal Dental College & Hospital, Chennai, Tamil Nadu' },
    { value: 'Nandha Dental College and Hospital, Tamil Nadu', label: 'Nandha Dental College and Hospital, Tamil Nadu' },
    { value: 'Priyadarshini Dental College & Hospital, Pandur, Tamil Nadu', label: 'Priyadarshini Dental College & Hospital, Pandur, Tamil Nadu' },
    { value: 'R.V.S. Dental College & Hospital, Sulur, Tamil Nadu', label: 'R.V.S. Dental College & Hospital, Sulur, Tamil Nadu' },
    { value: 'Ragas Dental College & Hospital, Chennai, Tamil Nadu', label: 'Ragas Dental College & Hospital, Chennai, Tamil Nadu' },
    { value: 'Rajas Dental College & Hospital, Kavalkinaru Jn, Tamil Nadu', label: 'Rajas Dental College & Hospital, Kavalkinaru Jn, Tamil Nadu' },
    { value: 'S.R.M. Dental College, Ramapuram, Chennai, Tamil Nadu', label: 'S.R.M. Dental College, Ramapuram, Chennai, Tamil Nadu' },
    { value: 'Sathyabama University Dental College and Hospital, Chennai, Tamil Nadu', label: 'Sathyabama University Dental College and Hospital, Chennai, Tamil Nadu' },
    { value: 'Saveetha Dental College & Hospital, Chennai, Tamil Nadu', label: 'Saveetha Dental College & Hospital, Chennai, Tamil Nadu' },
    { value: 'Sree Balaji Dental College & Hospital, Narayanpuram, Tamil Nadu', label: 'Sree Balaji Dental College & Hospital, Narayanpuram, Tamil Nadu' },
    { value: 'Sree Mookambika Institute of Dental Sciences, Kulasekharam, Tamil Nadu', label: 'Sree Mookambika Institute of Dental Sciences, Kulasekharam, Tamil Nadu' },
    { value: 'Sri Ramachandra Dental College & Hospital, Porur, Tamil Nadu', label: 'Sri Ramachandra Dental College & Hospital, Porur, Tamil Nadu' },
    { value: 'Sri Ramakrishna Dental College & Hospital, Coimbatore, Tamil Nadu', label: 'Sri Ramakrishna Dental College & Hospital, Coimbatore, Tamil Nadu' },
    { value: 'Sri Venkateswara Dental College & Hospital, Kancheepuram, Tamil Nadu', label: 'Sri Venkateswara Dental College & Hospital, Kancheepuram, Tamil Nadu' },
    { value: 'SRM Kattankulathur Dental College & Hospital, Kanchipuram, Tamil Nadu', label: 'SRM Kattankulathur Dental College & Hospital, Kanchipuram, Tamil Nadu' },
    { value: 'Tagore Dental College & Hospital, Chennai, Tamil Nadu', label: 'Tagore Dental College & Hospital, Chennai, Tamil Nadu' },
    { value: 'Tamil Nadu Government Dental College & Hospital, Chennai, Tamil Nadu', label: 'Tamil Nadu Government Dental College & Hospital, Chennai, Tamil Nadu' },
    { value: 'Thai Moogambigai Dental College & Hospital, Chennai, Tamil Nadu', label: 'Thai Moogambigai Dental College & Hospital, Chennai, Tamil Nadu' },
    { value: 'Vinayaka Mission’s Sankarachariyar Dental College, Salem, Tamil Nadu', label: 'Vinayaka Mission’s Sankarachariyar Dental College, Salem, Tamil Nadu' },
    { value: 'Vivekanandha Dental College for Women, Elayampalayam, Tamil Nadu', label: 'Vivekanandha Dental College for Women, Elayampalayam, Tamil Nadu' },
    { value: 'Mamata Institute of Dental Sciences, Telangana', label: 'Mamata Institute of Dental Sciences, Telangana' },
    { value: 'Army College of Dental Sciences, Secunderabad, Telangana', label: 'Army College of Dental Sciences, Secunderabad, Telangana' },
    { value: 'Government Dental College and Hospital, Hyderabad, Telangana', label: 'Government Dental College and Hospital, Hyderabad, Telangana' },
    { value: 'Kamineni Institute of Dental Sciences, Nalgonda, Telangana', label: 'Kamineni Institute of Dental Sciences, Nalgonda, Telangana' },
    { value: 'Malla Reddy Dental College for Women, Hyderabad, Telangana', label: 'Malla Reddy Dental College for Women, Hyderabad, Telangana' },
    { value: 'Malla Reddy Institute of Dental Sciences,Hyderabad, Telangana', label: 'Malla Reddy Institute of Dental Sciences,Hyderabad, Telangana' },
    { value: 'Mamata Dental College, Khamam, Telangana', label: 'Mamata Dental College, Khamam, Telangana' },
    { value: 'Meghna Institute of Dental Sciences, Nizamabad, Telangana', label: 'Meghna Institute of Dental Sciences, Nizamabad, Telangana' },
    { value: 'MNR DENTAL COLLEGE & HOSPITAL , MEDAK, Telangana', label: 'MNR DENTAL COLLEGE & HOSPITAL , MEDAK, Telangana' },
    { value: 'Panineeya Mahavidyalaya Institute of Dental Sciences & Research Centre, Hyderabad, Telangana', label: 'Panineeya Mahavidyalaya Institute of Dental Sciences & Research Centre, Hyderabad, Telangana' },
    { value: 'Sri Balaji Dental College, Hyderabad, Telangana', label: 'Sri Balaji Dental College, Hyderabad, Telangana' },
    { value: 'Sri Sai College of Dental Surgery, Vikarabad, Telangana', label: 'Sri Sai College of Dental Surgery, Vikarabad, Telangana' },
    { value: 'Sri Venkata Sai Institute of Dental Sciences, Hyderabad, Telangana', label: 'Sri Venkata Sai Institute of Dental Sciences, Hyderabad, Telangana' },
    { value: 'Tirumala Institute of Dental Sciences & Research Centre, Telangana', label: 'Tirumala Institute of Dental Sciences & Research Centre, Telangana' },
    { value: 'Agartala Government Dental College and IGM Hospital, Tripura', label: 'Agartala Government Dental College and IGM Hospital, Tripura' },
    { value: 'Babu Banarasi Das College of Dental Sciences, Lucknow, Uttar Pradesh', label: 'Babu Banarasi Das College of Dental Sciences, Lucknow, Uttar Pradesh' },
    { value: 'Career Institute of Dental Sciences & Hospital, Lucknow, Uttar Pradesh', label: 'Career Institute of Dental Sciences & Hospital, Lucknow, Uttar Pradesh' },
    { value: 'Chandra Dental College & Hospital, safedabad, Uttar Pradesh', label: 'Chandra Dental College & Hospital, safedabad, Uttar Pradesh' },
    { value: 'D.J. College of Dental Sciences & Research, Modi Nagar, Uttar Pradesh', label: 'D.J. College of Dental Sciences & Research, Modi Nagar, Uttar Pradesh' },
    { value: 'Dental College Azamgarh, Uttar Pradesh', label: 'Dental College Azamgarh, Uttar Pradesh' },
    { value: 'Dr. Ziauddin Ahmad Dental College, Aligarh, Uttar Pradesh', label: 'Dr. Ziauddin Ahmad Dental College, Aligarh, Uttar Pradesh' },
    { value: 'Faculty of Dental Sciences, Banaras Hindu University, Varanasi, Uttar Pradesh', label: 'Faculty of Dental Sciences, Banaras Hindu University, Varanasi, Uttar Pradesh' },
    { value: 'Faculty of Dental Sciences, Lucknow, Uttar Pradesh', label: 'Faculty of Dental Sciences, Lucknow, Uttar Pradesh' },
    { value: 'I.T.S. Centre for Dental Studies & Research, Ghaziabad, Uttar Pradesh', label: 'I.T.S. Centre for Dental Studies & Research, Ghaziabad, Uttar Pradesh' },
    { value: 'I.T.S. Dental College, Hospital & Research Centre, Gr. Noida, Uttar Pradesh', label: 'I.T.S. Dental College, Hospital & Research Centre, Gr. Noida, Uttar Pradesh' },
    { value: 'Inderprastha Dental College & Hospital, Ghaziabad, Uttar Pradesh', label: 'Inderprastha Dental College & Hospital, Ghaziabad, Uttar Pradesh' },
    { value: 'Institute of Dental Sciences, Bareilly, Uttar Pradesh', label: 'Institute of Dental Sciences, Bareilly, Uttar Pradesh' },
    { value: 'Institute of Dental Studies & Technology, Modinagar, Uttar Pradesh', label: 'Institute of Dental Studies & Technology, Modinagar, Uttar Pradesh' },
    { value: 'K.D. Dental College, Mathura, Uttar Pradesh', label: 'K.D. Dental College, Mathura, Uttar Pradesh' },
    { value: 'Kalka Dental College & Hospital, Meerut, Uttar Pradesh', label: 'Kalka Dental College & Hospital, Meerut, Uttar Pradesh' },
    { value: 'Kothiwal Dental College & Research Centre, Moradabad, Uttar Pradesh', label: 'Kothiwal Dental College & Research Centre, Moradabad, Uttar Pradesh' },
    { value: 'Maharana Pratap Dental College & Hospital, Kanpur, Uttar Pradesh', label: 'Maharana Pratap Dental College & Hospital, Kanpur, Uttar Pradesh' },
    { value: 'Purvanchal Institute of Dental Sciences, Gorakhpur, Uttar Pradesh', label: 'Purvanchal Institute of Dental Sciences, Gorakhpur, Uttar Pradesh' },
    { value: 'Rama Dental College, Hospital & Research Centre, Kanpur, Uttar Pradesh', label: 'Rama Dental College, Hospital & Research Centre, Kanpur, Uttar Pradesh' },
    { value: 'Santosh Dental College & Hospital, Ghaziabad, Uttar Pradesh', label: 'Santosh Dental College & Hospital, Ghaziabad, Uttar Pradesh' },
    { value: 'Saraswati Dental College, Lucknow, Uttar Pradesh', label: 'Saraswati Dental College, Lucknow, Uttar Pradesh' },
    { value: 'Sardar Patel Post Graduate Institute of Dental & Medical Sciences, Lucknow, Uttar Pradesh', label: 'Sardar Patel Post Graduate Institute of Dental & Medical Sciences, Lucknow, Uttar Pradesh' },
    { value: 'School of Dental Sciences, Greater Noida, Uttar Pradesh', label: 'School of Dental Sciences, Greater Noida, Uttar Pradesh' },
    { value: 'Shree Bankey Bihari Dental College & Research Centre, Masuri, Uttar Pradesh', label: 'Shree Bankey Bihari Dental College & Research Centre, Masuri, Uttar Pradesh' },
    { value: 'Subharati Dental College, Meerut, Uttar Pradesh', label: 'Subharati Dental College, Meerut, Uttar Pradesh' },
    { value: 'Teerthanker Mahaveer Dental College & Research Centre, Moradabad, Uttar Pradesh', label: 'Teerthanker Mahaveer Dental College & Research Centre, Moradabad, Uttar Pradesh' },
    { value: 'U.P. Rural Institute of Medical Sciences & Research, Saifai, Uttar Pradesh', label: 'U.P. Rural Institute of Medical Sciences & Research, Saifai, Uttar Pradesh' },
    { value: 'Narayan Swami Hospital and Dental College, Uttar Pradesh', label: 'Narayan Swami Hospital and Dental College, Uttar Pradesh' },
    { value: 'Seema Dental College & Hospital, Rishikesh, Uttar Pradesh', label: 'Seema Dental College & Hospital, Rishikesh, Uttar Pradesh' },
    { value: 'Uttaranchal Dental College & Medical Research Institute, Dehradun, Uttar Pradesh', label: 'Uttaranchal Dental College & Medical Research Institute, Dehradun, Uttar Pradesh' },
    { value: 'Burdwan Dental College, Rajbati, West Bengal', label: 'Burdwan Dental College, Rajbati, West Bengal' },
    { value: 'Dr. R. Ahmed Dental College & Hospital, Calcutta, West Bengal', label: 'Dr. R. Ahmed Dental College & Hospital, Calcutta, West Bengal' },
    { value: 'Gurunanak Institute of Dental Science & Research, Kolkatta, West Bengal', label: 'Gurunanak Institute of Dental Science & Research, Kolkatta, West Bengal' },
    { value: 'Haldia Institute of Dental Sciences and Research, Banbishnupur, West Bengal', label: 'Haldia Institute of Dental Sciences and Research, Banbishnupur, West Bengal' },
    { value: 'Kusum Devi Sunderlal Dugar Jain Dental College & Hospital, Kolkata, West Bengal', label: 'Kusum Devi Sunderlal Dugar Jain Dental College & Hospital, Kolkata, West Bengal' },
    { value: 'The North Bengal Dental College, Sushratangar, West Bengal', label: 'The North Bengal Dental College, Sushratangar, West Bengal' },
    // Add more options as needed
  ];

  const handleChangeSelect = (selected) => {
    setCollegenames(selected);
  };

  const handleChanges = (event) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  const handleChanges1 = (event) => {
    if (event.target.files.length > 0) {
      setFile1(event.target.files[0])
    }
  }



  useEffect(() => {
    setCollegename(collegenames.value)
  }, [collegenames.value])



  const handleChange = () => {
    setChecked(!checked);
  }
  const handlePresentation = (value) => {
    setFood(value);
  };

  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text')
    } else {
      setIcon(eyeOff)
      setType('password')
    }
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);

    if (username === "") {
      setLoading(false);
      alert("Please Enter Username");
      return;
    }

    if (email === "") {
      setLoading(false);
      alert("Please Enter Email");
      return;
    }

    if (password === "") {
      setLoading(false);
      alert("Please Enter Password");
      return;
    }

    if (password.length < 6) {
      setLoading(false);
      alert('Password must be at least 6 characters long.');
      return;
    }

    if (food === "") {
      setLoading(false);
      alert("Please Select Food Category");
      return;
    }

    if (collegename === undefined) {
      setLoading(false);
      alert("Please Select College");
      return;
    }

    if (PhoneNumber === "") {
      setLoading(false);
      alert("Please Enter Phone Number");
      return;
    }

    if (year === "") {
      setLoading(false);
      alert("Please Select Year");
      return;
    }

    const MAX_FILE_SIZE = 1000;//2MB
    //check is a file has been slected
    if (!file) {
      setLoading(false);
      alert("Please Choose a Photo")
      return;
    }

    //calculate the selected file's size in kiilobytes
    const fileSizeKiloBytes = file.size / 2000;
    const fileSizeKiloBytes1 = file1.size / 2000;

    //check if the file size is below the minmun limit
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setLoading(false);
      alert("Photo size is greater than 2MB")
      return;
    }
    //check is a file has been slected
    if (!file1) {
      setLoading(false);
      alert("Please Choose a Letter From HOD")
      return;
    }
    //check if the file size is below the minmun limit
    if (fileSizeKiloBytes1 > MAX_FILE_SIZE) {
      setLoading(false);
      alert("Letter size is greater than 2MB")
      return;
    }

    const credentials = {
      username,
      email,
      password,
      food,
      collegename,
      PhoneNumber,
      year
    }

    if (file) {
      const data = new FormData();
      const filename = file.name;
      data.append("name", filename);
      data.append("file", file);
      credentials.photos = filename;
      try {
        const res = await axios.post('https://back-ram.onrender.com/api/upload', data);
        setLoading(false);

      }
      catch (err) {
        setLoading(false);

      }
    }

    if (file1) {
      const data = new FormData();
      const filename = file1.name;
      data.append('name', filename);
      data.append('file', file1);
      credentials.letterhod = filename;

      try {
        const res = await axios.post('https://back-ram.onrender.com/api/letter', data);
        setLoading(false);


      } catch (err) {
        setLoading(false);

      }
    }


    try {
      const res = await axios.post("https://back-ram.onrender.com/api/auth/register", credentials);
      setLoading(false);

      alert("Your Registration is Successfully")
      res.data && window.location.replace("https://rzp.io/l/1stipsugc");
    } catch (error) {
      // setError(true);
      setLoading(false);
      setErrors({ message: error.response.data.message });


    }

    setLoading(false)

  };


  useEffect(() => {
    setLoading(false)
    { errors && alert(errors.message) }
  }, [errors]);

  console.log(email)

  return (
    <>
      {loading && <div class="preloader">
        <img src="./preloader.gif" alt="" />
      </div>}
      <form className="registerForm mt-5" onSubmit={handleSubmit}>
        <div className='container-fluid col-10'>
          <div className='row'>

            <div className='col-lg-12 col-12'>
              <div className='registerinput__details'>
                <input
                  type="text"
                  className="registerInput"
                  placeholder="Name"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </div>



            <div className='col-lg-6 col-12 mt-4'>
              <div className='registerinput__details1'>
                <input
                  type="text"
                  className="registerInput1 right-block d-block ms-auto"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  style={{ textTransform: "lowercase" }}
                />
              </div>
            </div>

            <div className='col-lg-6 col-12 mt-4'>
              <div className='registerinput__details1'>
                <div className='registershowup'>
                  <input type={type} name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" className='registerInput1 ' min={6} required />
                  <span class="iconsabsolute" onClick={handleToggle}>
                    <Icon class="iconRegister mr-10" icon={icon} size={25} />
                  </span>
                </div>

              </div>
            </div>


            <div className='col-lg-6 col-12 mt-4'>
              <div className='registerinput__details1'>
                {/* <h3 className='setCollegeRegisteropacity'> c</h3> */}
                <select className="registerInput1 right-block d-block ms-auto" onChange={(e) => setYear(e.target.value)}>
                  <option value="">Year</option>
                  <option value="I Year">I Year</option>
                  <option value="II Year">II Year</option>
                  <option value="III Year">III Year</option>
                  <option value="IV Year">IV Year</option>
                  <option value="Intern">Intern</option>
                </select>
              </div>
            </div>
            <div className='col-lg-6 col-12 mt-4'>
              <div className='registerinput__details1'>
                <input
                  type="text"
                  className="registerInput1"
                  placeholder="Enter Your Phone Number..."
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>

            <div className='col-lg-12 col-12 mt-4'>
              <div className='registerinput__details'>
                <h3 className='registerHeadsfood'>Food</h3>
                <div className='d-flex justify-content-start align-items-center'>
                  <div className='mt-3'>
                    <input type='radio' className='input_checkedevents' id='Vegeterian' value="Vegeterian" checked={food === "Vegeterian"}
                      onChange={() => { handlePresentation("Vegeterian") }} />
                    <label htmlFor='Vegeterian' className='inputchecked_parra mx-2'>Vegeterian</label>

                  </div>
                  <div className='mt-3 mx-3'>
                    <input type='radio' className='input_checkedevents' id='Non-Vegeterian' value="Non-Vegeterian" checked={food === "Non-Vegeterian"}
                      onChange={() => { handlePresentation("Non-Vegeterian") }} />
                    <label htmlFor='Vegeterian' className='inputchecked_parra mx-2'>Non-Vegeterian</label>

                  </div>
                </div>

              </div>
            </div>

            <div className='col-lg-12 col-12 mt-4'>
              <div className='registerinput__details'>


                <Select
                  options={options}
                  className="registerInputSelect"
                  value={collegenames}
                  placeholder="Select a College..."
                  onChange={handleChangeSelect}
                />
              </div>
            </div>

            <div className='col-lg-12 col-12 mt-5'>
              <div className='registerinput__details'>
                <div className='fileblessing'>
                  <input
                    type="file"
                    className='file_matter'
                    id="fileInput"
                    accept="image/png, image/gif, image/jpeg"
                    onChange={handleChanges}
                  />

                </div>
                <div className='d-flex justify-content-start align-items-center mt-3'>
                  <div>
                    <i class="bi bi-info-circle"></i>
                  </div>
                  <div className='mx-2'>
                    <p className='registerinfo_parra mb-0'>Upload Your Recent Passport Photo</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-12 col-12 mt-5'>
              <div className='registerinput__details'>
                <div className='fileblessing'>
                  <input
                    type="file"
                    className='file_matter'
                    id="fileInput"
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                    onChange={handleChanges1}
                  />

                </div>
                <div className='d-flex justify-content-start align-items-center mt-3'>
                  <div>
                    <i class="bi bi-info-circle"></i>
                  </div>
                  <div className='mx-2'>
                    <p className='registerinfo_parra mb-0'>Upload Your HOD/Dean Approval Letter</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-12 mt-5'>
              <div className='registerinput__details'>
                <div className='peak_instruments'>
                  <h2 className='termsParraHead mt-3'>Terms and Condition</h2>
                  <p className='termsParra mt-3'>
                    Terms and Conditions
                    1st Indian Prosthodontic Society National UG Convention has a limited Cancellation & Refund policy. In case of cancellation at any stage, bank transfer fee will not be refunded. It is applicable to bank transfer payments only. The refund will be done within one month after the Convention to the same account from which transaction was made.
                    Due to unexpected reasons, the venue (or) date for the program could be changed from the place (or) date already decided. The Convention organising committee hereby confirms its right to change the venues, dates and its speakers when necessary. All changes will be updated in the website. It's participants responsibility to visit our website for any updates.
                    It is mandatory to email all the cancellations well prior to the deadline mentioned below to Dr. Maheshwaran, Chennai 1stugipscon@gmail.com

                    Cancellation policy
                    All private data including Payment Details and Credit Card information will be transmitted securely using 256 bit SSL technology or similar. This is the same level of security available through your web browser when connecting to your Internet Bank provider.
                    Registrations are non-transferrable
                    Registration Refund / cancellation policy
                    No refunds will be done on cancellation of registration after 28th February 2024..
                    Refunds in the case of procedural lapses would mean
                    Money erroneously paid
                    Eligibility criteria not being met
                    Required documents not submitted
                    All the refunds will be processed only after one month of scheduled completion of Convention.
                    All the requests for the cancellation will be processed by the organising committee and any decision in this regard will be final and binding.In case of cancellation at any stage, bank transfer fee will not be refunded. It is applicable to bank transfer payments only.
                    Cancellations are to be requested in writing only to the Convention secretariat - 1stugipscon@gmail.com
                    Telephonic cancellations will not be entertained.
                    Only the base registration amount will be refunded and not the GST or Service charges and Bank Charges
                    In case of force majeure or Act of GOD, or natural calamities or pandemics the Convention COC with have the authority to convert physical registrations into digital platforms and the difference as appropriate will be refunded.
                    All legal jurisdictions will be that is binding for the Chennai, Tamil Nadu region.

                    Convention Policy
                    The Convention organising committee and the service provider of the website uses and protects any information that you give us when you use the Convention website.
                    We are committed to ensuring that your privacy is protected though all precautions are taken the COC do not take any liability for accidental hacking or breach of the website my malicious parties.


                    Disclosure
                    The 1st Indian prosthodontics society UG Convention is planned and presented by the Indian prosthodontics Society and the UG Convention organising Committee to provide attendees with a relevant and rewarding continuing education experience. However, neither the content of a course not the use of specific products in any program should be construed as indication endorsement or approval of the views presented or the products used by the IPS or the COC, any of the sub committees. Speakers are required to disclose to participants any financial, commercial or promotional interest in a product or company that may influence their presentations; however, the IPS or the COC shall not be liable for
                    the speaker's failure to disclose such interest. Please be advised that courses, speakers or scheduling may change without notice.
                  </p>
                </div>
              </div>

            </div>



            <div className='col-lg-12 col-12'>
              <div className='registerinput__details'>

                <div className='col-12 mt-5'>
                  <div className='d-flex justify-content-start align-items-start'>
                    <p className='termsParra'>
                      <i class="bi bi-info-circle"></i>
                    </p>
                    <p className='mx-2'>
                      Click on the register button if you are ready for payment (1500 including GST). If payment is undone, the registration username and password will be blocked.
                    </p>
                  </div>

                </div>
                <div className='d-flex justify-content-start align-items-center mt-5'>
                  <div>
                    <input type='checkbox' className='mb-0' value={checked} onChange={handleChange} />
                  </div>
                  <div className='mx-3'>
                    <p className='term_parra mb-1'>
                      I accept all the terms and conditions
                    </p>
                  </div>
                </div>

                <button className='registerButton mt-4 center-block d-block mx-auto' disabled={true}>
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

    </>
  )
}

export default RegisterForm