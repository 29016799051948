import React from 'react'
import SimpleSlider from '../../components/slider/SimpleSlider';
import One from '../../assests/home/one.webp';
import './Home.css'
import Dates from '../../components/Home/Dates';
import Organized from '../../components/Home/Organized';
import Right from '../../assests/home/right.webp';
import Left from '../../assests/home/left.webp'
import AboutHome from '../../components/Home/AboutHome';
import IPSBarrer from '../../components/Home/IPSBarrer';
import AboutLast from '../../components/Home/AboutLast';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';

const Home = () => {
    return (
        <>
            <Header />
            <div className='home_main'>
                <SimpleSlider />

                <div className='homemain_content'>
                    <div className='container-fluid col-10'>
                        <div className='row d-flex justify-content-center align-items-center'>
                            <div className='col-lg-6 col-12'>
                                <img src={One} className='img-fluid one_images center-block d-block mx-auto' alt="One Images" width='500' height='450' loading='lazy' />
                            </div>
                            <div className='col-lg-6 col-12'>
                                <h3 className='banner_head mt-lg-0 mt-4'>
                                    UNDERGRADUATE PROSTHODONTIC
                                </h3>
                                <h2 className='banner_highHead'>
                                    CONVENTION
                                </h2>
                                <div className='white_back mt-4'>
                                    <p className='banner_date'>
                                        22nd & 23rd March 2024, Chennai
                                    </p>
                                </div>
                                <div className='white_back mt-4'>
                                    <p className='banner_date'>
                                        Last Date To Apply:- 11th March 2024
                                    </p>
                                </div>
                                <a href="./schedule.pdf" className='link_all' target="_blank">
                                    <button className='button_events_home homes_buttons mt-4 center-block d-block mx-auto' >
                                        View Schedule
                                    </button>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>

                <div className='homedate_content'>
                    <Dates />
                </div>
            </div>

            <div className='organized_part'>
                <img src={Left} className='img-fluid left_images' alt='dotted_left' loading='lazy' width='500' height='450' />
                <img src={Right} className='img-fluid Right_images' alt='dotted_Right' loading='lazy' width='500' height='450' />
                <Organized />
            </div>

            <AboutHome />

            <IPSBarrer />

            <AboutLast />

        </>
    )
}

export default Home