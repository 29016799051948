import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Header from '../../components/header/Header';

function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        setMessage("");
        setError("");
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            setIsLoading(false)
        } else {
            try {
                const token = searchParams.get("token");
                const res = await axios.post("https://back-ram.onrender.com/api/auth/resetPassword", {token, password })
                setMessage(res.data.message);
            } catch (error) {
                setError(error.response.data.message)
            }finally{
                setIsLoading(false)
            }
           
        }
    }
    return (
        <div >
            <Header />
          <div className='eventformmain'>
    <h3 className='eventformhead'>
       Reset Password
    </h3>
    </div>
            <form  onSubmit={handleSubmit}>

            <div className='container-fluid mt-5'>
                    <div className='row'>
                        <div className='col-lg-12 col-12'>
                        <div className='registerinput__details'>
                <input type="password"
                    
                    placeholder="Enter new password"
                    className='registerInput'
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                    autoComplete='false'
                />
                <input type="password"
              
                    placeholder="Confirm new password"
                    className='registerInput'
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    required
                    autoComplete={false}
                />
                <button className='button_events mt-4 center-block d-block mx-auto mt-4'  disabled={isLoading}>Reset</button>
                </div>
                </div>
                </div>
                </div>
            </form>
            {
                message && <div >
                    <p className='text-center mt-4' style={{color:"green"}}>
                        {message}
                    </p>
                </div>
            }
            {
                error && <div >
                    <p className='text-center mt-4' style={{color:"red"}}>
                        {error}
                    </p>
                </div>
            }
        </div>
    );
}

export default ResetPassword;