import React, { useEffect, useState } from 'react';
import './Dates.css'

const Dates = () => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [sec, setSec] = useState(0);

  const dob = new Date('2024-03-22T00:00:00.000Z');

  useEffect(() => {
    const countdown = () => {
      const currentDate = new Date();
      const totalSeconds = (dob - currentDate) / 1000;

      const days = Math.floor(totalSeconds / 3600 / 24);
      const hours = Math.floor(totalSeconds / 3600) % 24;
      const mins = Math.floor(totalSeconds / 60) % 60;
      const sec = Math.floor(totalSeconds) % 60;

      setDays(days);
      setHours(functionTime(hours));
      setMins(functionTime(mins));
      setSec(functionTime(sec));
    };

    const functionTime = (time) => {
      return time < 10 ? `0${time}` : time;
    };

    const intervalId = setInterval(countdown, 1000);

    return () => clearInterval(intervalId);
  });
  return (
    <div className='container-fluid col-10'>
      <div className='row'>
        <div className='col-12'>
          <div className='dateBac d-flex justify-content-center align-items-center'>
            <div className='date_bacyellow mx-3 d-flex justify-content-center align-items-center flex-column'>
              <p className='mb-0 datenumber'>{"00"}</p>
              <p className='mb-0 datepara'>Days</p>
            </div>
            <div className='date_bacyellow mx-3 d-flex justify-content-center align-items-center flex-column'>
              <p className='mb-0 datenumber'>{"00"}</p>
              <p className='mb-0 datepara'>Hours</p>

            </div>
            <div className='date_bacyellow mx-3 d-flex justify-content-center align-items-center flex-column'>
              <p className='mb-0 datenumber'>{"00"}</p>
              <p className='mb-0 datepara'>Minutes</p>

            </div>
            <div className='date_bacyellow mx-3 d-flex justify-content-center align-items-center flex-column'>
              <p className='mb-0 datenumber'>{"00"}</p>
              <p className='mb-0 datepara'>Seconds</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dates;

